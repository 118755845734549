import React from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Typography, Button, ButtonGroup } from '@mui/material';

import { allowTracking, denyTracking } from '../../actions/consentCookie';

const Impressum = () => {

        const dispatch = useDispatch();
        //confirm the dialog and close it
        const confirmDialog = (playerChoice) => {
            if(playerChoice){
                dispatch(allowTracking());
            } else {
                dispatch(denyTracking());
            }
        }

        return(
        <Grid container justifyContent="center" alignItems="center" textAlign="center" padding={2}>
            <Grid container maxWidth="lg" justifyContent="center" textAlign="left">
                <Grid item xs={12}>
                    <Typography variant='h1' gutterBottom>Impressum</Typography>
                    <Typography>Lennart Wiehler</Typography>
                    <Typography>Sydneystr. 22</Typography>
                    <Typography>22297 Hamburg</Typography>
                    <Typography  mb={5}>quiz[at]multiplayer-quiz[dot]com</Typography>
                    <Button variant='contained' size="large" href="/">Home</Button>
                </Grid>
                <Grid item xs={12} mt={5}>
                    <Typography variant='h1' gutterBottom>Datenschutz</Typography>
                    <div>
                        <Typography variant="h1">Datenschutz&shy;erkl&auml;rung</Typography>
                        <Typography variant="h2" pt={1}>1. Datenschutz auf einen Blick</Typography>
                        <Typography variant="h3" pt={1}>Allgemeine Hinweise</Typography>
                        <Typography pt={2} pb={2}>
                            Die folgenden Hinweise geben einen einfachen &Uuml;berblick dar&uuml;ber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie pers&ouml;nlich
                            identifiziert werden k&ouml;nnen. Ausf&uuml;hrliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgef&uuml;hrten Datenschutzerkl&auml;rung.
                        </Typography>
                        <Typography variant="h3" pt={1}>Datenerfassung auf dieser Website</Typography>
                        <Typography variant="h4" pt={1}>Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser Website?</Typography>
                        <Typography pt={2} pb={2}>Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten k&ouml;nnen Sie dem Abschnitt &bdquo;Hinweis zur Verantwortlichen Stelle&ldquo; in dieser Datenschutzerkl&auml;rung entnehmen.</Typography>
                        <Typography variant="h4" pt={1}>Wie erfassen wir Ihre Daten?</Typography>
                        <Typography pb={2} pt={2}>Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei handelt es sich um Daten, die notwendig für das Spielen des Quiz sind (z.B. (Fantasy-)Namen).</Typography>
                        <Typography pb={2} pt={2}>
                            Andere Daten werden durch technische Notwendigkeiten automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z.&nbsp;B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
                            Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.
                        </Typography>
                        <Typography variant="h4" pt={1}>Wof&uuml;r nutzen wir Ihre Daten?</Typography>
                        <Typography pb={2} pt={2}>Die Daten werden erhoben, um eine fehlerfreie Bereitstellung der Website zu gew&auml;hrleisten und um die Funktion des Quiz bereitzustellen.</Typography>
                        <Typography variant="h4" pt={1}>Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?</Typography>
                        <Typography pb={2} pt={2}>
                            Sie haben jederzeit das Recht, unentgeltlich Auskunft &uuml;ber Herkunft, Empf&auml;nger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben au&szlig;erdem ein Recht, die Berichtigung oder L&ouml;schung dieser
                            Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, k&ouml;nnen Sie diese Einwilligung jederzeit f&uuml;r die Zukunft widerrufen. Au&szlig;erdem haben Sie das Recht, unter bestimmten Umst&auml;nden die
                            Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde zu.
                        </Typography>
                        <Typography pb={2} pt={2}>Hierzu sowie zu weiteren Fragen zum Thema Datenschutz k&ouml;nnen Sie sich jederzeit an uns wenden.</Typography>
                        <Typography variant="h2" pt={1}>2. Hosting</Typography>
                        <Typography pb={2} pt={2}>Wir hosten die Inhalte unserer Website bei folgendem Anbieter:</Typography>
                        <Typography variant="h3" pt={1}>Externes Hosting</Typography>
                        <Typography pb={2} pt={2}>
                            Diese Website wird extern gehostet. Die personenbezogenen Daten, die auf dieser Website erfasst werden, werden auf den Servern des Hosters / der Hoster gespeichert. Hierbei kann es sich v.&nbsp;a. um IP-Adressen, Kommunikationsdaten, Websitezugriffe und sonstige Daten, die &uuml;ber eine Website generiert werden, handeln.
                        </Typography>
                        <Typography pb={2} pt={2}>
                            Das externe Hosting erfolgt zum Zwecke der Vertragserf&uuml;llung gegen&uuml;ber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten Bereitstellung unseres
                            Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO). Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und
                            &sect; 25 Abs. 1 TDDDG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endger&auml;t des Nutzers (z.&nbsp;B. Device-Fingerprinting) im Sinne des TDDDG umfasst. Die Einwilligung ist jederzeit
                            widerrufbar.
                        </Typography>
                        <Typography pb={2} pt={2}>Unser(e) Hoster wird bzw. werden Ihre Daten nur insoweit verarbeiten, wie dies zur Erf&uuml;llung seiner Leistungspflichten erforderlich ist und unsere Weisungen in Bezug auf diese Daten befolgen.</Typography>
                        <Typography pb={2} pt={2}>Wir setzen folgende(n) Hoster ein:</Typography>
                        <Typography pb={2} pt={2}>
                            DigitalOcean<br />
                            101 6th Ave New York, NY 10013<br />
                            ATTN: Legal
                        </Typography>
                        <Typography variant="h4" pt={1}>Auftragsverarbeitung</Typography>
                        <Typography pb={2} pt={2}>
                            Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gew&auml;hrleistet, dass dieser die
                            personenbezogenen Daten unserer Websitebesucher nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
                        </Typography>
                        <Typography variant="h2" pt={1}>3. Allgemeine Hinweise und Pflicht&shy;informationen</Typography>
                        <Typography variant="h3" pt={1}>Datenschutz</Typography>
                        <Typography pb={2} pt={2}>
                            Die Betreiber dieser Seiten nehmen den Schutz Ihrer pers&ouml;nlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser
                            Datenschutzerkl&auml;rung.
                        </Typography>
                        <Typography pb={2} pt={2}>
                            Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen. Die vorliegende Datenschutzerkl&auml;rung
                            erl&auml;utert, welche Daten wir erheben und wof&uuml;r wir sie nutzen. Sie erl&auml;utert auch, wie und zu welchem Zweck das geschieht.
                        </Typography>
                        <Typography pb={2} pt={2}>
                            Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet (z.&nbsp;B. bei der Kommunikation per E-Mail) Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
                            m&ouml;glich.
                        </Typography>
                        <Typography variant="h3" pt={1}>Hinweis zur verantwortlichen Stelle</Typography>
                        <Typography pb={2} pt={2}>Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf dieser Website ist:</Typography>
                        <Typography pb={2} pt={2}>
                            Lennart Wiehler<br />
                            Sydneystr. 22<br />
                            22297 Hamburg
                        </Typography>

                        <Typography pb={2} pt={2}>
                            E-Mail: lennart.wiehler@generate-insights.com
                        </Typography>
                        <Typography pb={2} pt={2}>
                            Verantwortliche Stelle ist die nat&uuml;rliche Person, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.&nbsp;B. Namen, E-Mail-Adressen o. &Auml;.)
                            entscheidet.
                        </Typography>

                        <Typography variant="h3" pt={1}>Speicherdauer</Typography>
                        <Typography pb={2} pt={2}>
                            Soweit innerhalb dieser Datenschutzerkl&auml;rung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck f&uuml;r die Datenverarbeitung entf&auml;llt. Wenn Sie ein berechtigtes
                            L&ouml;schersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gel&ouml;scht, sofern wir keine anderen rechtlich zul&auml;ssigen Gr&uuml;nde f&uuml;r die Speicherung Ihrer personenbezogenen
                            Daten haben (z.&nbsp;B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die L&ouml;schung nach Fortfall dieser Gr&uuml;nde.
                        </Typography>
                        <Typography variant="h3" pt={1}>Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website</Typography>
                        <Typography pb={2} pt={2}>
                            Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien nach Art. 9 Abs. 1 DSGVO
                            verarbeitet werden. Im Falle einer ausdr&uuml;cklichen Einwilligung in die &Uuml;bertragung personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung au&szlig;erdem auf Grundlage von Art. 49 Abs. 1 lit. a DSGVO. Sofern Sie
                            in die Speicherung von Cookies oder in den Zugriff auf Informationen in Ihr Endger&auml;t (z.&nbsp;B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung zus&auml;tzlich auf Grundlage von &sect; 25 Abs. 1 TDDDG.
                            Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur Vertragserf&uuml;llung oder zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich, verarbeiten wir Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO.
                            Des Weiteren verarbeiten wir Ihre Daten, sofern diese zur Erf&uuml;llung einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann ferner auf Grundlage unseres
                            berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO erfolgen. &Uuml;ber die jeweils im Einzelfall einschl&auml;gigen Rechtsgrundlagen wird in den folgenden Abs&auml;tzen dieser Datenschutzerkl&auml;rung informiert.
                        </Typography>
                        <Typography variant="h3" pt={1}>Widerruf Ihrer Einwilligung zur Datenverarbeitung</Typography>
                        <Typography pb={2} pt={2}>
                            Viele Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer ausdr&uuml;cklichen Einwilligung m&ouml;glich. Sie k&ouml;nnen eine bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtm&auml;&szlig;igkeit der bis zum Widerruf
                            erfolgten Datenverarbeitung bleibt vom Widerruf unber&uuml;hrt.
                        </Typography>
                        <Typography variant="h3" pt={1}>Widerspruchsrecht gegen die Datenerhebung in besonderen F&auml;llen sowie gegen Direktwerbung (Art. 21 DSGVO)</Typography>
                        <Typography pb={2} pt={2}>
                            WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GR&Uuml;NDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN
                            DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH F&Uuml;R EIN AUF DIESE BESTIMMUNGEN GEST&Uuml;TZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKL&Auml;RUNG. WENN SIE
                            WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR K&Ouml;NNEN ZWINGENDE SCHUTZW&Uuml;RDIGE GR&Uuml;NDE F&Uuml;R DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE
                            UND FREIHEITEN &Uuml;BERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUS&Uuml;BUNG ODER VERTEIDIGUNG VON RECHTSANSPR&Uuml;CHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
                        </Typography>
                        <Typography pb={2} pt={2}>
                            WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES
                            GILT AUCH F&Uuml;R DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH
                            ART. 21 ABS. 2 DSGVO).
                        </Typography>
                        <Typography variant="h3" pt={1}>Beschwerde&shy;recht bei der zust&auml;ndigen Aufsichts&shy;beh&ouml;rde</Typography>
                        <Typography pb={2} pt={2}>
                            Im Falle von Verst&ouml;&szlig;en gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbeh&ouml;rde, insbesondere in dem Mitgliedstaat ihres gew&ouml;hnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des
                            mutma&szlig;lichen Versto&szlig;es zu. Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
                        </Typography>
                        <Typography variant="h3" pt={1}>Recht auf Daten&shy;&uuml;bertrag&shy;barkeit</Typography>
                        <Typography pb={2} pt={2}>
                            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erf&uuml;llung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem g&auml;ngigen, maschinenlesbaren Format aush&auml;ndigen zu
                            lassen. Sofern Sie die direkte &Uuml;bertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.
                        </Typography>
                        <Typography variant="h3" pt={1}>Auskunft, Berichtigung und L&ouml;schung</Typography>
                        <Typography pb={2} pt={2}>
                            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft &uuml;ber Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empf&auml;nger und den Zweck der Datenverarbeitung und
                            ggf. ein Recht auf Berichtigung oder L&ouml;schung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten k&ouml;nnen Sie sich jederzeit an uns wenden.
                        </Typography>
                        <Typography variant="h3" pt={1}>Recht auf Einschr&auml;nkung der Verarbeitung</Typography>
                        <Typography pb={2} pt={2}>
                            Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu k&ouml;nnen Sie sich jederzeit an uns wenden. Das Recht auf Einschr&auml;nkung der Verarbeitung besteht in folgenden
                            F&auml;llen:
                        </Typography>
                        <ul style={{color:"white"}}>
                            <li>
                                Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, ben&ouml;tigen wir in der Regel Zeit, um dies zu &uuml;berpr&uuml;fen. F&uuml;r die Dauer der Pr&uuml;fung haben Sie das Recht, die
                                Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                            </li>
                            <li>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtm&auml;&szlig;ig geschah/geschieht, k&ouml;nnen Sie statt der L&ouml;schung die Einschr&auml;nkung der Datenverarbeitung verlangen.</li>
                            <li>
                                Wenn wir Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen, Sie sie jedoch zur Aus&uuml;bung, Verteidigung oder Geltendmachung von Rechtsanspr&uuml;chen ben&ouml;tigen, haben Sie das Recht, statt der L&ouml;schung die
                                Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                            </li>
                            <li>
                                Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abw&auml;gung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen &uuml;berwiegen, haben Sie das
                                Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                            </li>
                        </ul>
                        <Typography pb={2} pt={2}>
                            Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschr&auml;nkt haben, d&uuml;rfen diese Daten &ndash; von ihrer Speicherung abgesehen &ndash; nur mit Ihrer Einwilligung oder zur Geltendmachung, Aus&uuml;bung oder
                            Verteidigung von Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden eines wichtigen &ouml;ffentlichen Interesses der Europ&auml;ischen Union oder eines
                            Mitgliedstaats verarbeitet werden.
                        </Typography>
                        <Typography variant="h3" pt={1}>SSL- bzw. TLS-Verschl&uuml;sselung</Typography>
                        <Typography pb={2} pt={2}>
                            Diese Seite nutzt aus Sicherheitsgr&uuml;nden und zum Schutz der &Uuml;bertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschl&uuml;sselung.
                            Eine verschl&uuml;sselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von &bdquo;http://&ldquo; auf &bdquo;https://&ldquo; wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
                        </Typography>
                        <Typography pb={2} pt={2}>Wenn die SSL- bzw. TLS-Verschl&uuml;sselung aktiviert ist, k&ouml;nnen die Daten, die Sie an uns &uuml;bermitteln, nicht von Dritten mitgelesen werden.</Typography>
                        <Typography variant="h2" pt={1}>4. Datenerfassung auf dieser Website</Typography>
                        <Typography variant="h3" pt={1}>Cookies</Typography>
                        <Typography pb={2} pt={2}>
                            Unsere Internetseiten verwenden so genannte &bdquo;Cookies&ldquo;. Cookies sind kleine Datenpakete und richten auf Ihrem Endger&auml;t keinen Schaden an. Sie werden entweder vor&uuml;bergehend f&uuml;r die Dauer einer Sitzung
                            (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endger&auml;t gespeichert. Session-Cookies werden nach Ende Ihres Besuchs automatisch gel&ouml;scht. Permanente Cookies bleiben auf Ihrem Endger&auml;t gespeichert, bis Sie
                            diese selbst l&ouml;schen oder eine automatische L&ouml;schung durch Ihren Webbrowser erfolgt.
                        </Typography>
                        <Typography pb={2} pt={2}>
                            Der Multiplayer-Quiz-Cookie hat nur eine Funktion - die Speicherung des Spielernamens, um dem Spieler ein vereinfachtes "Login" zu ermöglichen.
                        </Typography>
                        <Typography pb={2} pt={2}>
                            Cookies, die zur Durchf&uuml;hrung des elektronischen Kommunikationsvorgangs, zur Bereitstellung bestimmter, von Ihnen erw&uuml;nschter Funktionen (z.&nbsp;B. f&uuml;r die Warenkorbfunktion) oder zur Optimierung der Website (z.&nbsp;B.
                            Cookies zur Messung des Webpublikums) erforderlich sind (notwendige Cookies), werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein berechtigtes
                            Interesse an der Speicherung von notwendigen Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine Einwilligung zur Speicherung von Cookies und vergleichbaren Wiedererkennungstechnologien
                            abgefragt wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TDDDG); die Einwilligung ist jederzeit widerrufbar.
                        </Typography>
                        <Typography pb={2} pt={2}>
                            Sie k&ouml;nnen Ihren Browser so einstellen, dass Sie &uuml;ber das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies f&uuml;r bestimmte F&auml;lle oder generell ausschlie&szlig;en
                            sowie das automatische L&ouml;schen der Cookies beim Schlie&szlig;en des Browsers aktivieren. Bei der Deaktivierung von Cookies wird die Funktionalit&auml;t dieser Website eingeschr&auml;nkt sein.
                        </Typography>
                        <Typography variant="h3" pt={1}>Nutzung von Google Analytics</Typography>

                        <Typography pb={2} pt={2}>
                                Wir verwenden Google Analytics, um die Website-Nutzung zu analysieren. Die daraus gewonnenen Daten werden genutzt, um unsere Website sowie Werbemaßnahmen zu optimieren. 
                                Google Analytics wird uns von Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland) bereitgestellt. Google verarbeitet die Daten zur Website-Nutzung in unserem Auftrag und verpflichtet sich vertraglich zu Maßnahmen, um die Sicherheit und Vertraulichkeit der verarbeiteten Daten zu gewährleisten.
                                Während Ihres Website-Besuchs werden u.a. folgende Daten an Google übermittelt:<br></br><br></br>
                                Aufgerufene Seiten<br></br>
                                Die Erreichung von "Website-Zielen" (z.B. Kontaktanfragen und Newsletter-Anmeldungen)<br></br>
                                Ihr Verhalten auf den Seiten (beispielsweise Verweildauer, Klicks, Scrolltiefe)<br></br>
                                Ihr ungefährer Standort (Land und Stadt)<br></br>
                                Ihre Internetadresse (IP-Adresse)<br></br>
                                Technische Informationen wie Browser, Internetanbieter, Endgerät und Bildschirmauflösung<br></br>
                                Herkunftsquelle Ihres Besuchs (d.h. über welche Website bzw. über welches Werbemittel Sie zu uns gekommen sind)<br></br>
                                Eine zufallsgenerierte User-ID<br></br>
                                Es werden keine persönliche Daten wie Name, Anschrift oder Kontaktdaten an Google Analytics übertragen.<br></br><br></br>
                                Diese Daten werden an Server von Google in den USA übertragen. Wir weisen darauf hin, dass in den USA datenschutzrechtlich nicht das gleiche Schutzniveau wie innerhalb der EU garantiert werden kann. 
                                Google Analytics speichert Cookies in Ihrem Webbrowser für die Dauer von zwei Jahren seit Ihrem letzten Besuch. Diese Cookies enthaltene eine zufallsgenerierte User-ID, mit der Sie bei zukünftigen Website-Besuchen wiedererkannt werden können.
                                Die aufgezeichneten Daten werden zusammen mit der zufallsgenerierten User-ID gespeichert, was die Auswertung pseudonymer Nutzerprofile ermöglicht. Diese nutzerbezogenen Daten werden automatisch nach 14 Monaten gelöscht. Sonstige Daten bleiben in aggregierter Form unbefristet gespeichert.
                                Sollten Sie mit der Erfassung nicht einverstanden sein, können Sie diese mit der einmaligen Installation des Browser-Add-ons zur Deaktivierung von Google Analytics unterbinden oder durch das Ablehnen der Cookies über unseren Cookie-Einstellungs-Dialog.
                        </Typography>
                        <ButtonGroup>
                            <Button variant='contained' onClick={(e) => confirmDialog(true)}>Confirm all</Button>
                            <Button onClick={(e) => confirmDialog(false)} >Save</Button>
                        </ButtonGroup>
                        <Typography variant="h3" pt={1}>Server-Log-Dateien</Typography>
                        <Typography pb={2} pt={2}>Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns &uuml;bermittelt. Dies sind:</Typography>
                        <ul style={{color:"white"}}>
                            <li>Browsertyp und Browserversion</li>
                            <li>verwendetes Betriebssystem</li>
                            <li>Referrer URL</li>
                            <li>Hostname des zugreifenden Rechners</li>
                            <li>Uhrzeit der Serveranfrage</li>
                            <li>IP-Adresse</li>
                        </ul>
                        <Typography pb={2} pt={2}>Eine Zusammenf&uuml;hrung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.</Typography>
                        <Typography pb={2} pt={2}>
                            Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der technisch fehlerfreien Darstellung und der Optimierung seiner Website &ndash; hierzu m&uuml;ssen
                            die Server-Log-Files erfasst werden.
                        </Typography>
                        <Typography variant="h3" pt={1}>Anfrage per E-Mail, Telefon oder Telefax</Typography>
                        <Typography pb={2} pt={2}>
                            Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet.
                            Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
                        </Typography>
                        <Typography pb={2} pt={2}>
                            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erf&uuml;llung eines Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich
                            ist. In allen &uuml;brigen F&auml;llen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit.
                            a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.
                        </Typography>
                        <Typography pb={2} pt={2}>
                            Die von Ihnen an uns per Kontaktanfragen &uuml;bersandten Daten verbleiben bei uns, bis Sie uns zur L&ouml;schung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck f&uuml;r die Datenspeicherung entf&auml;llt
                            (z.&nbsp;B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen &ndash; insbesondere gesetzliche Aufbewahrungsfristen &ndash; bleiben unber&uuml;hrt.
                        </Typography>
                    </div> 
                    <Typography variant='h1' gutterBottom>Privacy Statement</Typography>
                    <div>
                    <Typography variant="h1">Privacy Policy</Typography>
                        <Typography variant="h2" pt={1}>1. Privacy Overview</Typography>
                        <Typography variant="h3" pt={1}>General Information</Typography>
                        <Typography pb={2} pt={2}>
                            The following information provides a simple overview of what happens to your personal data when you visit this website. Personal data is any data by which you can be personally identified. For detailed information on the subject of data protection, please refer to our privacy policy listed below this text.
                        </Typography>
                        <Typography variant="h3" pt={1}>Data Collection on this Website</Typography>
                        <Typography variant="h4" pt={1}>Who is responsible for data collection on this website?</Typography>
                        <Typography pb={2} pt={2}>Data processing on this website is carried out by the website operator. You can find their contact details in the section "Notice concerning the responsible party" in this privacy policy.</Typography>
                        <Typography variant="h4" pt={1}>How do we collect your data?</Typography>
                        <Typography pb={2} pt={2}>Your data is collected, firstly, by you providing it to us. This includes data necessary for playing the quiz (e.g., (fantasy) names).</Typography>
                        <Typography pb={2} pt={2}>
                            Other data is collected automatically by our IT systems when you visit the website. This is primarily technical data (e.g., internet browser, operating system, or time of the page view). The collection of this data occurs automatically as soon as you enter this website.
                        </Typography>
                        <Typography variant="h4" pt={1}>What do we use your data for?</Typography>
                        <Typography pb={2} pt={2}>The data is collected to ensure the error-free provision of the website and to provide the functionality of the quiz.</Typography>
                        <Typography variant="h4" pt={1}>What rights do you have regarding your data?</Typography>
                        <Typography pb={2} pt={2}>
                            You have the right to receive information about the origin, recipients, and purpose of your stored personal data at any time without charge. You also have the right to request the correction or deletion of this data. If you have given consent to data processing, you can revoke this consent at any time for the future. Furthermore, you have the right to request the restriction of the processing of your personal data under certain circumstances. You also have the right to lodge a complaint with the competent supervisory authority.
                        </Typography>
                        <Typography pb={2} pt={2}>You can contact us at any time regarding this and other questions on the subject of data protection.</Typography>
                        <Typography variant="h2" pt={1}>2. Hosting</Typography>
                        <Typography pb={2} pt={2}>We host the contents of our website with the following provider:</Typography>
                        <Typography variant="h3" pt={1}>External Hosting</Typography>
                        <Typography pb={2} pt={2}>
                            This website is hosted externally. The personal data collected on this website is stored on the servers of the host(s). This may include IP addresses, communication data, website accesses, and other data generated through a website.
                        </Typography>
                        <Typography pb={2} pt={2}>
                            External hosting is carried out for the purpose of fulfilling contracts with our potential and existing customers (Art. 6 para. 1 lit. b GDPR) and in the interest of a secure, fast, and efficient provision of our online offer by a professional provider (Art. 6 para. 1 lit. f GDPR). If consent has been requested, processing is carried out exclusively on the basis of Art. 6 para. 1 lit. a GDPR and § 25 para. 1 TTDSG, insofar as the consent includes the storage of cookies or access to information on the user's end device (e.g., device fingerprinting) as defined by the TTDSG. Consent can be revoked at any time.
                        </Typography>
                        <Typography pb={2} pt={2}>Our host(s) will only process your data to the extent necessary to fulfill their performance obligations and follow our instructions regarding this data.</Typography>
                        <Typography pb={2} pt={2}>We use the following host(s):</Typography>
                        <Typography pb={2} pt={2}>
                            DigitalOcean<br />
                            101 6th Ave New York, NY 10013<br />
                            ATTN: Legal
                        </Typography>
                        <Typography variant="h4" pt={1}>Data Processing Agreement</Typography>
                        <Typography pb={2} pt={2}>
                            We have concluded a data processing agreement (DPA) for the use of the above service. This is a legally required contract that ensures that this provider processes the personal data of our website visitors only according to our instructions and in compliance with the GDPR.
                        </Typography>
                        <Typography variant="h2" pt={1}>3. General Information and Mandatory Information</Typography>
                        <Typography variant="h3" pt={1}>Data Protection</Typography>
                        <Typography pb={2} pt={2}>
                            The operators of these pages take the protection of your personal data very seriously. We treat your personal data confidentially and in accordance with the statutory data protection regulations and this privacy policy.
                        </Typography>
                        <Typography pb={2} pt={2}>
                            When you use this website, various personal data is collected. Personal data is data by which you can be personally identified. This privacy policy explains what data we collect and what we use it for. It also explains how and for what purpose this happens.
                        </Typography>
                        <Typography pb={2} pt={2}>
                            We point out that data transmission over the Internet (e.g., when communicating via email) can have security vulnerabilities. Complete protection of the data from access by third parties is not possible.
                        </Typography>
                        <Typography variant="h3" pt={1}>Notice Concerning the Responsible Party</Typography>
                        <Typography pb={2} pt={2}>The responsible party for data processing on this website is:</Typography>
                        <Typography pb={2} pt={2}>
                            Lennart Wiehler<br />
                            Sydneystr. 22<br />
                            22297 Hamburg
                        </Typography>
                        <Typography pb={2} pt={2}>
                            Email: lennart.wiehler@generate-insights.com
                        </Typography>
                        <Typography pb={2} pt={2}>
                            The responsible party is the natural person who alone or jointly with others decides on the purposes and means of processing personal data (e.g., names, email addresses, etc.).
                        </Typography>

                        <Typography variant="h3" pt={1}>Storage Duration</Typography>
                        <Typography pb={2} pt={2}>
                            Unless a more specific storage period has been stated within this privacy policy, your personal data will remain with us until the purpose for data processing ceases to apply. If you make a legitimate request for deletion or revoke your consent to data processing, your data will be deleted unless we have other legally permissible reasons for storing your personal data (e.g., tax or commercial law retention periods); in the latter case, deletion will occur after these reasons cease to apply.
                        </Typography>
                        <Typography variant="h3" pt={1}>General Information on the Legal Basis of Data Processing on This Website</Typography>
                        <Typography pb={2} pt={2}>
                            If you have consented to data processing, we process your personal data based on Art. 6 para. 1 lit. a GDPR or Art. 9 para. 2 lit. a GDPR, if special categories of data according to Art. 9 para. 1 GDPR are processed. In the case of express consent to the transfer of personal data to third countries, data processing is also based on Art. 49 para. 1 lit. a GDPR. If you have consented to the storage of cookies or to access information in your end device (e.g., via device fingerprinting), data processing is additionally based on § 25 para. 1 TTDSG. Consent can be revoked at any time. If your data is required for the fulfillment of a contract or for pre-contractual measures, we process your data based on Art. 6 para. 1 lit. b GDPR. Furthermore, we process your data if it is required to fulfill a legal obligation based on Art. 6 para. 1 lit. c GDPR. Data processing may also be based on our legitimate interest according to Art. 6 para. 1 lit. f GDPR. The relevant legal bases for each individual case are provided in the following paragraphs of this privacy policy.
                        </Typography>
                        <Typography variant="h3" pt={1}>Revocation of Your Consent to Data Processing</Typography>
                        <Typography pb={2} pt={2}>
                            Many data processing operations are only possible with your explicit consent. You can revoke an already given consent at any time. The legality of the data processing carried out until the revocation remains unaffected by the revocation.
                        </Typography>
                        <Typography variant="h3" pt={1}>Right to Object to Data Collection in Special Cases and to Direct Advertising (Art. 21 GDPR)</Typography>
                        <Typography pb={2} pt={2}>
                            IF THE DATA PROCESSING IS BASED ON ART. 6 PARA. 1 LIT. E OR F GDPR, YOU HAVE THE RIGHT TO OBJECT TO THE PROCESSING OF YOUR PERSONAL DATA AT ANY TIME FOR REASONS ARISING FROM YOUR PARTICULAR SITUATION; THIS ALSO APPLIES TO PROFILING BASED ON THESE PROVISIONS. THE RESPECTIVE LEGAL BASIS ON WHICH A PROCESSING IS BASED CAN BE FOUND IN THIS PRIVACY POLICY. IF YOU OBJECT, WE WILL NO LONGER PROCESS YOUR AFFECTED PERSONAL DATA UNLESS WE CAN DEMONSTRATE COMPELLING LEGITIMATE GROUNDS FOR THE PROCESSING THAT OVERRIDE YOUR INTERESTS, RIGHTS, AND FREEDOMS OR THE PROCESSING IS FOR THE ESTABLISHMENT, EXERCISE, OR DEFENSE OF LEGAL CLAIMS (OBJECTION ACCORDING TO ART. 21 PARA. 1 GDPR).
                        </Typography>
                        <Typography pb={2} pt={2}>
                            IF YOUR PERSONAL DATA IS PROCESSED FOR DIRECT MARKETING PURPOSES, YOU HAVE THE RIGHT TO OBJECT AT ANY TIME TO THE PROCESSING OF PERSONAL DATA CONCERNING YOU FOR SUCH MARKETING; THIS ALSO APPLIES TO PROFILING INSOFAR AS IT IS RELATED TO SUCH DIRECT MARKETING. IF YOU OBJECT, YOUR PERSONAL DATA WILL THEN NO LONGER BE USED FOR DIRECT MARKETING PURPOSES (OBJECTION ACCORDING TO ART. 21 PARA. 2 GDPR).
                        </Typography>
                        <Typography variant="h3" pt={1}>Right to Lodge a Complaint with the Competent Supervisory Authority</Typography>
                        <Typography pb={2} pt={2}>
                            In the event of violations of the GDPR, data subjects have the right to lodge a complaint with a supervisory authority, particularly in the Member State of their habitual residence, their place of work, or the place of the alleged violation. The right to lodge a complaint is without prejudice to other administrative or judicial remedies.
                        </Typography>
                        <Typography variant="h3" pt={1}>Right to Data Portability</Typography>
                        <Typography pb={2} pt={2}>
                            You have the right to have data that we process automatically based on your consent or in fulfillment of a contract handed over to you or a third party in a common, machine-readable format. If you request the direct transfer of the data to another controller, this will only be done to the extent technically feasible.
                        </Typography>
                        <Typography variant="h3" pt={1}>Information, Correction, and Deletion</Typography>
                        <Typography pb={2} pt={2}>
                            Within the framework of the applicable legal provisions, you have the right at any time to free information about your stored personal data, its origin and recipients, and the purpose of the data processing and, if applicable, a right to correction or deletion of this data. For this purpose, as well as for further questions on the subject of personal data, you can contact us at any time.
                        </Typography>
                        <Typography variant="h3" pt={1}>Right to Restriction of Processing</Typography>
                        <Typography pb={2} pt={2}>
                            You have the right to request the restriction of the processing of your personal data. For this purpose, you can contact us at any time. The right to restriction of processing exists in the following cases:
                        </Typography>
                        <ul style={{color:"white"}}>
                            <li>
                                If you dispute the accuracy of your personal data stored by us, we usually need time to verify this. For the duration of the verification, you have the right to request the restriction of the processing of your personal data.
                            </li>
                            <li>
                                If the processing of your personal data was/is unlawful, you can request the restriction of data processing instead of deletion.
                            </li>
                            <li>
                                If we no longer need your personal data, but you need it to exercise, defend, or assert legal claims, you have the right to request the restriction of the processing of your personal data instead of deletion.
                            </li>
                            <li>
                                If you have lodged an objection pursuant to Art. 21 para. 1 GDPR, a balance must be struck between your interests and ours. As long as it has not been determined whose interests prevail, you have the right to request the restriction of the processing of your personal data.
                            </li>
                        </ul>
                        <Typography pb={2} pt={2}>
                            If you have restricted the processing of your personal data, this data may only be processed – apart from being stored – with your consent or for the assertion, exercise, or defense of legal claims or for the protection of the rights of another natural or legal person or for reasons of important public interest of the European Union or a Member State.
                        </Typography>
                        <Typography variant="h3" pt={1}>SSL or TLS Encryption</Typography>
                        <Typography pb={2} pt={2}>
                            This site uses SSL or TLS encryption for security reasons and to protect the transmission of confidential content, such as orders or inquiries that you send to us as the site operator. You can recognize an encrypted connection by the fact that the address line of the browser changes from "http://" to "https://" and by the lock symbol in your browser line.
                        </Typography>
                        <Typography pb={2} pt={2}>
                            If SSL or TLS encryption is activated, the data you transmit to us cannot be read by third parties.
                        </Typography>
                        <Typography variant="h2" pt={1}>4. Data Collection on This Website</Typography>
                        <Typography variant="h3" pt={1}>Cookies</Typography>
                        <Typography pb={2} pt={2}>
                            Our websites use so-called "cookies". Cookies are small data packets that do no harm to your device. They are either temporarily stored for the duration of a session (session cookies) or permanently (permanent cookies) on your device. Session cookies are automatically deleted after your visit. Permanent cookies remain stored on your device until you delete them or they are automatically deleted by your web browser.
                        </Typography>
                        <Typography pb={2} pt={2}>
                            Only cookies First Party Cookies.
                        </Typography>
                        <Typography pb={2} pt={2}>
                            The multiplayer quiz cookie has only one function - to store the player's name to enable a simplified "login" for the player.
                        </Typography>
                        <Typography pb={2} pt={2}>
                            Cookies that are necessary to carry out the electronic communication process, to provide certain functions you have requested (e.g., for the shopping cart function), or to optimize the website (e.g., cookies to measure the web audience) are stored based on Art. 6 para. 1 lit. f GDPR, unless another legal basis is specified. The website operator has a legitimate interest in storing necessary cookies for the technically error-free and optimized provision of its services. If consent for the storage of cookies and comparable recognition technologies has been requested, processing is carried out exclusively based on this consent (Art. 6 para. 1 lit. a GDPR and § 25 para. 1 TTDSG); the consent can be revoked at any time.
                        </Typography>
                        <Typography pb={2} pt={2}>
                            You can configure your browser to inform you about the setting of cookies and to allow cookies only in individual cases, to exclude the acceptance of cookies for certain cases or in general, and to enable the automatic deletion of cookies when closing the browser. If cookies are deactivated, the functionality of this website will be limited.
                        </Typography>
                        <Typography variant="h3" pt={1}>Usage of Google Analytics</Typography>

                        <Typography pb={2} pt={2}>
                            We use Google Analytics to analyze website usage. The data obtained is used to optimize our website and advertising efforts. Google Analytics is provided to us by Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Ireland). Google processes the data on website usage on our behalf and is contractually committed to measures to ensure the security and confidentiality of the processed data. During your website visit, the following data is transmitted to Google:<br></br><br></br>
                            Pages visited<br></br>
                            Achievement of "website goals" (e.g., contact requests and newsletter sign-ups)<br></br>
                            Your behavior on the pages (such as dwell time, clicks, scroll depth)<br></br>
                            Your approximate location (country and city)<br></br>
                            Your internet address (IP address)<br></br>
                            Technical information such as browser, internet provider, device, and screen resolution<br></br>
                            Source of your visit (i.e., which website or advertising medium you came to us from)<br></br>
                            A randomly generated user ID<br></br>
                            No personal data such as name, address, or contact details is transmitted to Google Analytics.<br></br><br></br>
                            This data is transmitted to Google servers in the USA. We would like to point out that data protection in the USA may not guarantee the same level of protection as within the EU. Google Analytics stores cookies in your web browser for a period of two years since your last visit. These cookies contain a randomly generated user ID, which can be used to recognize you on future website visits. The recorded data is stored together with the randomly generated user ID, enabling the evaluation of pseudonymous user profiles. These user-related data are automatically deleted after 14 months. Other data is retained in aggregated form indefinitely. If you do not agree with the collection, you can prevent it by installing the browser add-on to disable Google Analytics or by rejecting cookies through our cookie settings dialog.
                        </Typography>
                        <ButtonGroup>
                            <Button variant='contained' onClick={(e) => confirmDialog(true)}>Confirm all</Button>
                            <Button onClick={(e) => confirmDialog(false)} >Save</Button>
                        </ButtonGroup>
                        <Typography variant="h3" pt={1}>Server Log Files</Typography>
                        <Typography pb={2} pt={2}>
                            The provider of the pages automatically collects and stores information in so-called server log files, which your browser automatically transmits to us. These are:
                        </Typography>
                        <ul style={{color:"white"}}>
                            <li>Browser type and browser version</li>
                            <li>Operating system used</li>
                            <li>Referrer URL</li>
                            <li>Hostname of the accessing computer</li>
                            <li>Time of the server request</li>
                            <li>IP address</li>
                        </ul>
                        <Typography pb={2} pt={2}>
                            This data is not merged with other data sources.
                        </Typography>
                        <Typography pb={2} pt={2}>
                            The collection of this data is based on Art. 6 para. 1 lit. f GDPR. The website operator has a legitimate interest in the technically error-free presentation and optimization of his website - for this purpose, the server log files must be recorded.
                        </Typography>
                        <Typography variant="h3" pt={1}>Inquiry by Email, Phone, or Fax</Typography>
                        <Typography pb={2} pt={2}>
                            If you contact us by email, phone, or fax, your inquiry, including all resulting personal data (name, inquiry), will be stored and processed by us for the purpose of handling your request. We do not pass on this data without your consent.
                        </Typography>
                        <Typography pb={2} pt={2}>
                            The processing of these data is based on Art. 6 para. 1 lit. b GDPR if your request is related to the fulfillment of a contract or is necessary for the implementation of pre-contractual measures. In all other cases, the processing is based on our legitimate interest in the effective handling of inquiries addressed to us (Art. 6 para. 1 lit. f GDPR) or on your consent (Art. 6 para. 1 lit. a GDPR) if this has been requested; the consent can be revoked at any time.
                        </Typography>
                        <Typography pb={2} pt={2}>
                            The data sent by you to us via contact requests remain with us until you request us to delete it, revoke your consent to store it, or the purpose for the data storage ceases to apply (e.g., after your request has been processed). Mandatory statutory provisions - in particular, statutory retention periods - remain unaffected.
                        </Typography>


                               
                    </div>           
                </Grid>
            </Grid>
        </Grid>
    )

}

export default Impressum