import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography, Button } from '@mui/material';
import { keyframes } from '@mui/system';
import moment from 'moment';
import FlipMove from 'react-flip-move';
import CountUp from 'react-countup';
import md5 from 'md5';

import HostNavButton from '../HostNavButton/HostNavButton';
import Language from '../../Language/Language';

const Result = () => {
    //every text is stored within this constant
    //translation are done here - just enter a different language
    const componentContent = {
        language_DE:{
            time:'Zeit',
            name:'Name',
            points:'Punkte',
            question: 'Frage',
            result:'Endstand',
            homeButton:'Startseite'
        },
        language_EN:{
            time:'Time',
            name:'Name',
            points:'Points',
            question:'Question',
            result:'Result',
            homeButton:'Homepage'
        },
        language_FR:{
            time:'Temps',
            name:'Nom',
            points:'Points',
            question:'Question',
            result:'Résultat',
            homeButton:'Homepage'
        }
    }

    const winningStrike = {
        1:"First Start",
        2:"Double Win",
        3:"Hat Trick",
        4:"Fantastic Four",
        5:"High Five",
        6:"Super Six",
        7:"Lucky Seven",
        8:"Octa Streak",
        9:"Exceptional Nine",
        10:"Perfect Ten",
    }

     const burningBorder = keyframes ({
        "from": { 
            textShadow: "-.1em 0 .3em #fefcc9, .1em -.1em .3em #feec85, -.2em -.2em .4em #ffae34, .2em -.3em .3em #ec760c, -.2em -.4em .4em #cd4606, .1em -.5em .7em #973716, .1em -.7em .7em #451b0e;" 
        },
        "45%":  { 
            textShadow: ".1em -.2em .5em #fefcc9, .15em 0 .4em #feec85, -.1em -.25em .5em #ffae34, .15em -.45em .5em #ec760c, -.1em -.5em .6em #cd4606, 0 -.8em .6em #973716, .2em -1em .8em #451b0e;"
        },
        "70%": { 
            textShadow: "-.1em 0 .3em #fefcc9, .1em -.1em .3em #feec85, -.2em -.2em .6em #ffae34, .2em -.3em .4em #ec760c, -.2em -.4em .7em #cd4606, .1em -.5em .7em #973716, .1em -.7em .9em #451b0e;" 
        },
        "to": { 
            textShadow: "-.1em -.2em .6em #fefcc9, -.15em 0 .6em #feec85, .1em -.25em .6em #ffae34, -.15em -.45em .5em #ec760c, .1em -.5em .6em #cd4606, 0 -.8em .6em #973716, -.2em -1em .8em #451b0e;" 
        }
      });


    //++++++++++++++++STORE SELECTOR++++++++++++++++
    const playQuiz = useSelector((state) => state.PlayQuiz); //get the current playQuiz to geht all the results
    const selectedLanguage = useSelector((state) => state.Language); //get the selected language from the store. set within navigation component
    const playerId = useSelector((state) => state.Cookie.playerId);

    //++++++++++++++++STATE SETTINGS++++++++++++++++++
    const [timeLeft, setTimeLeft] = useState(2);//timer is used to start the sorting of the new order. After the time is up the new values are displayed
    const [playersOrdered, setPlayersOrdered] = useState(playQuiz.players); //order of the player changes with the next functions

    //sort the players by the value of the round before. After the time is up, the players are sorted by the value including the last round
    const arr = playQuiz.players.sort(function(a, b) {
        return  (b.playerTotalPoints-b.playerLastPoints) - (a.playerTotalPoints-a.playerLastPoints) || (a.playerTotalTime-a.playerLastTime) - (b.playerTotalTime-b.playerLastTime);
    });

    //++++++++++++++++USE EFFECT EVENTS++++++++++++++++
    //timer function. After the time is up, the sorting changes
    useEffect(() => {
        let timer = setTimeout(() => {
            if(timeLeft > 0){
                setTimeLeft(timeLeft-1);
            }else if (timeLeft === 0){
                const change = [...arr].sort(function(a, b) {
                    return  b.playerTotalPoints - a.playerTotalPoints || a.playerTotalTime - b.playerTotalTime;
                });
                setPlayersOrdered(change);
            }
        }, 1000);

        return () => clearTimeout(timer);
    },[timeLeft,arr])

    let questionNumber = playQuiz.navigation.currentQuestion + 1

    return(
        <Grid container justifyContent="center" textAlign="center" alignContent="center" sx={{height: '100vH'}}>
            <Language/>
            <Grid container maxWidth="lg" justifyContent="left" textAlign="left" alignContent="center" p={3}>
                <Grid item xs={12} align="center" mb={2}>
                    <Typography variant="overline">QUIZ: {playQuiz.quizId}</Typography>
                    <Typography variant="h2">{playQuiz.navigation.currentType === "end" ? componentContent[selectedLanguage].result : componentContent[selectedLanguage].question + " " + questionNumber +"/"+ playQuiz.questions.length}</Typography>
                </Grid>
            <Grid item xs={12}  align="center">
                <Grid container alignItems="left" mb={2} p={1}  sx={{backgroundColor:"rgba(0,0,0,.7)",}}>
                    <Grid item xs={1} md={2} textAlign="right" pr={2}>
                        <Typography variant="h4"></Typography>
                    </Grid>
                    <Grid item xs={4} md={6} textAlign="left">
                        <Typography variant="h4">{componentContent[selectedLanguage].name}</Typography>
                    </Grid>
                    <Grid item xs={3} md={2} textAlign="left">
                        <Typography variant="h4">{componentContent[selectedLanguage].points}</Typography>
                    </Grid>
                    <Grid item xs={4} md={2} textAlign="left">
                        <Typography variant="h4">{componentContent[selectedLanguage].time}</Typography>
                    </Grid>
                </Grid>
                <FlipMove
                    staggerDurationBy="30"
                    duration={700}
                    typeName="div"
                >
                    {playersOrdered.map((player, index) => (
                        <Grid 
                            container 
                            alignItems="left" 
                            textAlign="left" 
                            mb={2} 
                            p={1} 
                            key={player.playerId} 
                            sx={{
                                backgroundColor: md5(playerId) === player.playerId ? "grey" :"rgba(0,0,0,.7)",
                                color:"grey"
                            }}
                        >
                            <Grid item xs={1} md={2} pr={2} textAlign="right" sx={{position:"relative"}}>
                                <Typography variant="h4" sx={{color: player.playerSessionIsActive ? "":"black",fontStyle: player.playerSessionIsActive ? "":"italic"}}>
                                    {index + 1} 
                                </Typography>
                                
                            </Grid>
                            <Grid item xs={4} md={6} textAlign="left">
                                <Typography variant="h4" sx={{animation:`${player.playerLastBonusPoints > 0 ? burningBorder : ""} 1s infinite`,color: player.playerSessionIsActive ? "":"black",fontStyle: player.playerSessionIsActive ? "":"italic"}}>
                                    {player.playerName}
                                </Typography>
                                {player.playerLastBonusPoints > 0 ?
                                    <Typography variant="caption" style={{color:"red"}}>{winningStrike[player.playerAnswerStrike]}</Typography>
                                    :
                                    ""
                                }
                            </Grid>
                            <Grid item xs={3} md={2}>
                                <Typography variant='h4' sx={{color: player.playerSessionIsActive ? "":"black",fontStyle: player.playerSessionIsActive ? "":"italic"}}>
                                    <CountUp 
                                        start={(player.playerTotalPoints-player.playerLastPoints)}
                                        end={(player.playerTotalPoints)}
                                        duration={3}
                                    />
                                    <Typography variant='caption' sx={{color: player.playerSessionIsActive ? "":"black",fontStyle: player.playerSessionIsActive ? "":"italic"}}>
                                        {player.playerLastPoints > 0 ? ' (+' + (player.playerLastPoints - player.playerLastBonusPoints) + (player.playerLastBonusPoints > 0 ? " + "+ player.playerLastBonusPoints + " Bonus" :"")  + ')' : ''}
                                    </Typography>
                                </Typography>
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <Typography variant='h4' sx={{color: player.playerSessionIsActive ? "":"black",fontStyle: player.playerSessionIsActive ? "":"italic"}}>
                                    <CountUp 
                                        start={(moment.duration(player.playerTotalTime-player.playerLastTime, 'milliseconds').asSeconds())}
                                        end={(moment.duration(player.playerTotalTime, 'milliseconds').asSeconds())}
                                        duration={3}
                                        decimals={1}
                                        suffix="s"
                                    />
                                    <Typography variant='caption' sx={{color: player.playerSessionIsActive ? "":"black",fontStyle: player.playerSessionIsActive ? "":"italic"}}>
                                        {' (+'+(moment.duration(player.playerLastTime, 'milliseconds').asSeconds()).toFixed(1) + 's) '}
                                    </Typography>
                                </Typography>
                            </Grid>
                        </Grid>
                    ))}
                </FlipMove>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <HostNavButton/>
                    </Grid>
                    <Grid item xs={12}>
                        {playQuiz.navigation.currentType === "end" ? 
                                <Button variant='contained' href='/'>{componentContent[selectedLanguage].homeButton}</Button>
                            :""
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

        
    )

}

export default Result