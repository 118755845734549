import React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from '@mui/material';

const QuestionCountdownSponsor = () => {

    const componentContent = {
        language_DE:{
            nextQuestion:'Nächste Frage wird präsentiert von'
        },
        language_EN:{
            question:'Next Question is presented by'
        },
        language_FR:{
            question:'Prochaine question présentée par'
        }
    }
    const currentQuestion = useSelector((state) => state.Question); //get the current question to get the image infos
    const selectedLanguage = useSelector((state) => state.Language); //get the selected language from the store. set within navigation component

return (
    currentQuestion ?
            currentQuestion.sponsorInformation.isSponsored ?
                    <div>
                        <Typography variant='caption'>{componentContent[selectedLanguage].nextQuestion}</Typography>
                        <br/>
                        <br/>
                        <img src={currentQuestion.sponsorInformation.sponsorLogo} alt="sponsor Logo" style={{ maxHeight: '90px',maxWidth:'250px' }}/>
                    </div>
                :
                ""
            :
            ""    
    )
}

export default QuestionCountdownSponsor