import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Chip, Typography, Button, Stack } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import { RWebShare } from "react-web-share";

import HostNavButton from '../HostNavButton/HostNavButton';
import Language from '../../Language/Language';

const Lobby = () => {
    //every text is stored within this constant
    //translation are done here - just enter a different language
    const componentContent = {
        language_DE:{
            shareMessage:"Trete meinem Quiz bei auf multiplayer-quiz.com",
            motivationMessage:[
                'Auf die Plätze, fertig, los!'
                ,'Let’s get this party started!'
                ,'Ab geht die Post!'
                ,'Ran an den Speck!'
                ,'Volle Kraft voraus!'
                ,'Feuer frei!'
                ,'Jetzt geht’s rund!'
                ,'Wir legen los wie die Feuerwehr!'
                ,'Auf ins Getümmel!'
                ,'Machen wir das Unmögliche möglich!'
                ,'Jetzt wird’s ernst!'
                ,'Auf die Koffer, fertig, los!'
                ,'Die Show kann beginnen!'
                ,'Start frei!'
                ,'Hoch die Hände, Wochenende!'
                ,'Jetzt geht’s ab wie Schmitz’ Katze!'
                ,'Rien ne va plus – alles geht los!'
                ,'Der Countdown läuft!'
                ,'Jetzt geben wir Vollgas!'
                ,'Auf ins Abenteuer!'
                ,'Los, lasst die Spiele beginnen!'
                ,'Schwingen wir die Hufe!'
                ,'Wir rocken das Ding!'
                ,'Jetzt geht’s ans Eingemachte!'
                ,'Auf ins nächste Kapitel!'
                ,'Zeit, den Turbo zu zünden!'
                ,'Und ab die wilde Fahrt!'
                ,'Legen wir eine Schippe drauf!'
                ,'Lasst uns die Bühne rocken!'
                ,'Auf zur nächsten Runde!'
            ],
            demotivationMessage:[
                'hat keine Lust mehr auf die Party.',
                'verabschiedet sich ins Wochenende.',
                'geht jetzt lieber ein Nickerchen machen.',
                'hat die Bühne verlassen.',
                'macht sich vom Acker.',
                'hat genug für heute.',
                'ist raus – ab zur nächsten Mission!',
                'sagt Tschüssikowski!',
                'geht sich jetzt eine Pizza holen.',
                'hat den Exit-Knopf gedrückt.',
                'hat den Hut genommen.',
                'ist jetzt offline.',
                'verabschiedet sich in den Feierabend.',
                'hat die Segel gestrichen.',
                'hat genug gequizzt für heute.',
                'sagt Auf Wiedersehen!',
                'geht auf Tauchstation.',
                'macht einen Abgang.',
                'nimmt den nächsten Ausfahrt.',
                'hat den Rückzug angetreten.'
            ]
        },
        language_EN:{
            shareMessage:"Join my Quiz at multiplayer-quiz.com",
            motivationMessage:[
                "Ready, set, go!"
                ,"Let’s get this party started!"
                ,"Here we go!"
                ,"Let’s hit the ground running!"
                ,"Full steam ahead!"
                ,"Fire away!"
                ,"Let's get the ball rolling!"
                ,"We're off to the races!"
                ,"Time to dive in!"
                ,"Let's make the impossible possible!"
                ,"Now it’s getting serious!"
                ,"Let's hit the road!"
                ,"The show must go on!"
                ,"All systems go!"
                ,"Let’s kick things off!"
                ,"It's go time!"
                ,"Ready or not, here we come!"
                ,"The clock is ticking!"
                ,"Time to put the pedal to the metal!"
                ,"Off on an adventure!"
                ,"Let the games begin!"
                ,"Let’s get a move on!"
                ,"Let’s rock and roll!"
                ,"Now we're cooking!"
                ,"On to the next chapter!"
                ,"Time to hit the turbo!"
                ,"Buckle up for a wild ride!"
                ,"Let’s crank it up a notch!"
                ,"Time to take the stage!"
                ,"Round two, here we go!"
            ],
            demotivationMessage:[
                "is done with the party."
                ,"is off for the weekend."
                ,"is going to take a nap now."
                ,"has left the stage."
                ,"is outta here."
                ,"has had enough for today."
                ,"is out – on to the next mission!"
                ,"says bye-bye!"
                ,"is going to grab a pizza now."
                ,"has pressed the exit button."
                ,"has left the building."
                ,"is now offline."
                ,"is signing off for the day."
                ,"has thrown in the towel."
                ,"has had enough quizzing for today."
                ,"says goodbye!"
                ,"is going off the grid."
                ,"is making an exit."
                ,"is taking the next exit."
                ,"has retreated."
            ]
        },
        language_FR:{
            shareMessage:"Rejoins mon quiz sur multiplayer-quiz.com",
            motivationMessage:[
                "Prêts, partez!"
                ,"Que la fête commence!"
                ,"C'est parti!"
                ,"En avant toute!"
                ,"À fond les ballons!"
                ,"Feu à volonté!"
                ,"On démarre en trombe!"
                ,"C'est parti mon kiki!"
                ,"Plongeons dans le vif du sujet!"
                ,"Rendons l'impossible possible!"
                ,"Maintenant, ça devient sérieux!"
                ,"En route!"
                ,"Le spectacle doit continuer!"
                ,"Tous les systèmes sont prêts!"
                ,"Lançons les festivités!"
                ,"C'est l'heure de vérité!"
                ,"Prêts ou non, nous voilà!"
                ,"Le compte à rebours a commencé!"
                ,"Il est temps de mettre la gomme!"
                ,"En avant pour l'aventure!"
                ,"Que les jeux commencent!"
                ,"On se bouge!"
                ,"Let's rock and roll!"
                ,"Ça va chauffer!"
                ,"Passons au chapitre suivant!"
                ,"Il est temps d'accélérer!"
                ,"Accrochez-vous pour une balade folle!"
                ,"On monte d'un cran!"
                ,"Il est temps de prendre la scène!"
                ,"Deuxième round, c'est parti!"
            ],
            demotivationMessage:[
                "n'a plus envie de faire la fête."
                ,"part pour le week-end."
                ,"va faire une sieste maintenant."
                ,"a quitté la scène."
                ,"se casse."
                ,"en a assez pour aujourd'hui."
                ,"est parti – en route pour la prochaine mission !"
                ,"dit Tchao!"
                ,"va se chercher une pizza maintenant."
                ,"a appuyé sur le bouton de sortie."
                ,"a pris son chapeau."
                ,"est maintenant hors ligne."
                ,"part en congé."
                ,"a hissé les voiles."
                ,"en a assez quizzé pour aujourd'hui."
                ,"dit au revoir!"
                ,"va plonger."
                ,"fait une sortie."
                ,"prend la prochaine sortie."
                ,"s'est retiré."
            ]
        }
    }


    //++++++++++++++++STORE SELECTOR++++++++++++++++
    const playQuiz = useSelector((state) => state.PlayQuiz); //get the current playQuiz to show playerName etc.
    const selectedLanguage = useSelector((state) => state.Language);

    return(
        <Grid container justifyContent="center" alignItems="center" textAlign="center" sx={{height: '100vH'}}>
            <Grid container maxWidth="lg" justifyContent="center" textAlign="center">
                <Language />
                <Grid container maxWidth="sm" justifyContent="center" textAlign="center">
                <Grid item xs={12} >
                    <Typography >PIN:</Typography>
                </Grid>
                <Grid item xs={12} mb={3} pb={2} >
                    <Typography variant='h1'>{playQuiz.quizId}</Typography>
                    <Stack direction="row" spacing={2} justifyContent="center">
                        <RWebShare
                            data={{
                            text: componentContent[selectedLanguage].shareMessage,
                            url: window.location.href,
                            title: "Multiplayer-Quiz: " + playQuiz.quizId,
                            }}
                            onClick={() => console.log("shared successfully!")}
                        >
                            <Button variant='outlined' size="small" color="secondary"><ShareIcon/></Button>
                        </RWebShare>
                    </Stack>
                </Grid>
                </Grid>
                <Grid container maxWidth="lg" justifyContent="center" textAlign="center">
                {playQuiz.players.map((player) => (
                   
                    <Grid item lg={2} md={3} sm={6} xs={12} mb={3} key={player.playerId}>
                        {player.playerSessionIsActive ? //random function needs to stay in the rendering, otherwise every message would be the same
                            <div>
                                <Chip color="secondary" sx={{fontSize:"1.5rem"}} label={player.playerName} />
                                <div><Typography variant='caption'>{'"' + componentContent[selectedLanguage].motivationMessage[Math.floor(Math.random() * componentContent[selectedLanguage].motivationMessage.length)] + '"'}</Typography></div> 
                            </div>
                            :
                            <div>
                                <Chip color="secondary" sx={{fontSize:"1.5rem", backgroundColor:"grey"}} label={player.playerName} />
                                <div><Typography variant='caption'>{componentContent[selectedLanguage].demotivationMessage[Math.floor(Math.random() * componentContent[selectedLanguage].demotivationMessage.length)]}</Typography></div> 
                            </div>
                        } 
                    </Grid>
                ))}
                </Grid>
                <Grid item xs={12}>
                    <HostNavButton/>
                </Grid> 
            </Grid>
        </Grid>
        
    )

}

export default Lobby