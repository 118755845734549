import React from 'react';
import {  useDispatch,useSelector } from 'react-redux';
import { Grid, Button, Stack } from '@mui/material';

import {setLanguage} from '../../actions/language.js';
import { changePlayerCookie } from '../../actions/player.js';


const Language = () => {

    //Variables
    const dispatch = useDispatch();
    const playerData = useSelector((state) => state.Cookie);
    const selectedLanguage = useSelector((state) => state.Language);

    if(selectedLanguage !== playerData.playerLanguage && playerData.playerLanguage){
        dispatch(setLanguage(playerData.playerLanguage));
    }

    const handleSwitchLanguage = (newLanguage) => {
        const newplayerData = {
            playerId: playerData.playerId,
            playerName: playerData.playerName,
            playerLanguage:newLanguage
        }
        dispatch(setLanguage(newLanguage));
        dispatch(changePlayerCookie(newplayerData));
    }


    return(
        <Grid container justifyContent="center">
            <Stack mb={4} spacing={1} direction="row">
                <Button variant="text" size="small" onClick={(e) => handleSwitchLanguage("language_EN")}><img src="images/flag_uk.png" alt="UK Flag" style={{ width: '25px', }}/></Button>
                <Button variant="text" size="small" onClick={(e) => handleSwitchLanguage("language_DE")}><img src="images/flag_ger.png" alt="UK Flag" style={{ width: '25px', }}/></Button>
                <Button variant="text" size="small" onClick={(e) => handleSwitchLanguage("language_FR")}><img src="images/flag_fr.png" alt="UK Flag" style={{ width: '25px', }}/></Button>
            </Stack>
        </Grid>
    )

}

export default Language