import { GET_PLAY_QUIZ, PLAYER_LEAVE, CLEAR_QUESTION } from '../constants/actionTypes.js';
import * as api from '../api';
import {SocketConnection as socket} from '../api/socketConnection.js';
 

//A playQuiz is a temp copy of an original quiz. Every game is organized by a playQuiz. 
//To start a game, a playQuiz is created and users can join this playQuiz.
//A playQuiz is deleted after the game automatically

//++++++++++++++++++CREATE PLAY QUIZ+++++++++++++++++++++++
export const createPlayQuiz = (quiz) => async (dispatch) => {
  try {

    //create the quiz via API call
    const { data } = await api.createPlayQuiz(quiz); //the new playQuiz is received
    
    //if the quiz is created push it to the store and join the quiz within the socket room
    if(data){
      window.open("/play?quizId="+data.quizId, "_self")
    }
  //catch error block
  } catch (error) {
    console.log(error);
  }
};

//++++++++++++++++++CREATE PLAY QUIZ+++++++++++++++++++++++
export const createPlayQuizNewRound = (quiz) => async (dispatch) => {
  try {

    //create the quiz via API call
    const { data } = await api.createPlayQuiz(quiz); //the new playQuiz is received
    
    //if the quiz is created push it to the store and join the quiz within the socket room
    if(data){
      socket.emit('joinNewRound', { newQuizId: data.quizId });
      window.open("/play?quizId="+data.quizId, "_self")
    }
  //catch error block
  } catch (error) {
    console.log(error);
  }
};

//++++++++++++++++++GET PLAY QUIZ+++++++++++++++++++++++
export const getPlayQuiz = (quizId) => async (dispatch) => {
  try {
    //get the playQuiz via the quizId. The quizId is NOT the mongoDB _id. It is the seven digit number
    const { data } = await api.getPlayQuiz(quizId); //the playQuiz is received
    
    //if quiz is received, push it to the store
    if(data){
      dispatch({ type: GET_PLAY_QUIZ, payload: data });
    }

  //catch error block
  } catch (error) {
    console.log(error);
  }
}

//++++++++++++++++++GET PLAY QUIZ AND OPEN+++++++++++++++++++++++
export const getPlayQuizAndOpen = (quizId) => async (dispatch) => {
  try {
    //get the playQuiz via the quizId. The quizId is NOT the mongoDB _id. It is the seven digit number
    const { data } = await api.getPlayQuiz(quizId); //the playQuiz is received
    
    //if quiz is received, push it to the store
    if(data){
      window.open("/play?quizId="+data.quizId, "_self")
    }

  //catch error block
  } catch (error) {
    console.log(error);
  }
}

//++++++++++++++++++JOIN PLAY QUIZ+++++++++++++++++++++++
export const reJoinPlayQuiz = (quizId, playerCookie) => async () => {
  try {
    //join a playQuiz via a socket room. Every playQuiz has its own room
    socket.emit('reJoinPlayQuiz', { room: quizId, playerId: playerCookie.playerId });

  //catch error block
  } catch (error) {
    console.log(error);
  }
} 

//++++++++++++++++++ADD PLAYER+++++++++++++++++++++++
export const addPlayerToQuiz = (_id, playerData, hostId) => async (dispatch) => {
  try {

    //add the player via websocket. With the websocket the info is pushed to the room and every other player gets the info that a new player joined.
    //the _id is the mongodb-playQuiz-id. PlayerData contains name and cookie value
    socket.emit('addPlayerToQuiz', { id: _id, newPlayer: playerData, hostId: hostId });

  //catch error block
  } catch (error) {
    console.log(error);
  }
}

//++++++++++++++++++PLAYER LEAVE+++++++++++++++++++++++
export const playerLeave = () => async (dispatch) => {
  try {
    //the custom leave emit is used (and not the default disconnect) to that every status and parameter to "player left"
    socket.emit('leave');

    //clear the playQuiz and question store for the player who left
    dispatch({ type: PLAYER_LEAVE, payload: '' });
    dispatch({ type: CLEAR_QUESTION, payload: '' });

  //catch error block
  } catch (error) {
    console.log(error);
  }
}

//++++++++++++++++++NAVIGATE THROUGH QUIZ+++++++++++++++++++++++
export const navigatePlayQuiz = (playQuizId, oldNavType, newNavType, playQuizNavQuestion, playQuizCreatedAt, playQuizOriginId) => async () => {
  try {
    //navigation through the playQuiz is managed by the hostButton in "Elements". Only the host can execute this button
    //with the newNavType and the newQuestion the playQuiz knows where to go
    //the websocket is uesed (and not the api) to push the info to every other player in the room
    socket.emit('navigatePlayQuiz', { id: playQuizId, oldNavType: oldNavType, newNavType: newNavType, newNavQuestion: playQuizNavQuestion, playQuizCreatedAt: playQuizCreatedAt, playQuizOriginId: playQuizOriginId });

  //catch error block
  } catch (error) {
    console.log(error);
  }
}
 
//++++++++++++++++++ANSWER QUESTION+++++++++++++++++++++++
export const answerPlayQuizQuestion =  (questionId, anwserType, givenAnswer, neededTime, availableTime, playerCookie, quizId,selectedLanguage,answerStrike) => async (dispatch) => {

  try {

    //executed when a player answers a question.
    //the neededTime is calculated on the client side. AnswerType is needed to differentiate between multichoice or freetext or...
    //websocket is used to push the info to every other player in the room. Emit action on server side is only executed when every player has answered
    socket.emit('answerPlayQuizQuestion', {questionId: questionId, anwserType: anwserType, givenAnswer: givenAnswer, neededTime: neededTime, availableTime: availableTime, playerCookie: playerCookie, quizId: quizId,selectedLanguage:selectedLanguage,answerStrike:answerStrike });
    
  //catch error block
  } catch (error) {
    console.log(error);
  }
}

//++++++++++++++++++END ROUND+++++++++++++++++++++++
export const endPlayQuizRound = (questionId, anwserType) => async () => {
  try {
    //end round is triggered by the timer. A round ends when every player answered OR when the time is up
    //websocket is used to push the information to every other player within the room
    socket.emit('endPlayQuizRound', {questionId: questionId, anwserType: anwserType});

  //catch error block
  } catch (error) {
    console.log(error);
  }
}

//++++++++++++++++++COUNTDOWN TIMER+++++++++++++++++++++++
export const countdownTimer = (timeLeft) => async (dispatch) => {
  try {
  //Executed every second during a quiz to update the time left
  socket.emit('countdownTimer', {timeLeft: timeLeft});
  //catch error block
  } catch (error) {
    console.log(error);
  }
};