import Cookies from 'universal-cookie'; 
 
const cookies = new Cookies();


//++++++++++++++++++SET COOKIE+++++++++++++++++++++++
export const allowTracking = () => async () => {

  cookies.set( "playerConsent", "allow", { path: '/',maxAge: 95000000000 , sameSite: 'strict', secure: true  });

}

//++++++++++++++++++SET COOKIE+++++++++++++++++++++++
export const denyTracking = () => async () => {

  cookies.set( "playerConsent", "deny", { path: '/',maxAge: 95000000000 , sameSite: 'strict', secure: true  });

}

