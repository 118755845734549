import {SocketConnection as socket} from './socketConnection.js';
import { GET_PLAY_QUIZ, SET_RESPONSE, SET_TIMER } from '../constants/actionTypes.js';
import { getPlayQuizAndOpen } from '../actions/playQuiz.js';

//global socketlistener to enable the store with websockets
//socketListener is initialized within the index file dispatch and getState are bound to the store
//everytime the socket events playQuiz or serverResponse are executed on the server side the data object is dispatched to the corresponding store
export default function socketListener (dispatch, getState) {

    //listens to every playQuiz socket event
    socket.on('playQuiz', (playQuiz) => { 
        try {
            const { data } = playQuiz.playQuizData;

            //two ways of data objects are send to the client from the server
            //default contains data object and playQuiz data is stored within
            //different way is to send the data without the data object directly within playQuizData
            if(data){
                dispatch({ type: SET_TIMER, payload: data.currentRound.timeLeft });
                dispatch({ type: GET_PLAY_QUIZ, payload: data });
            }else if(playQuiz.playQuizData){
                dispatch({ type: SET_TIMER, payload: playQuiz.playQuizData.currentRound.timeLeft });
                dispatch({ type: GET_PLAY_QUIZ, payload: playQuiz.playQuizData });
            }           
        } catch (error) {
            console.log(error);
        }
    });
    
    //listens to every serverResponse socket event
    socket.on('serverResponse', (response) => { //listens to every playQuiz socket event

        //Store data can be used everywhere within the application. 
        //With that way you are able to send every message from the server to the client
        dispatch({ type: SET_RESPONSE, payload: response });
    });
    
    //listens to every startNewRound socket event
    socket.on('startNewRound', (newQuizId) => { //listens to every playQuiz socket event
        dispatch(getPlayQuizAndOpen(newQuizId))
        
    });
    
    //listens to every startNewRound socket event
    socket.on('timer', (timer) => { //listens to every playQuiz socket event
        dispatch({ type: SET_TIMER, payload: timer });
        
    });
}