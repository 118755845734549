import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Grid, Typography } from '@mui/material';

const PlainQuestion = () => {

    const componentContent = {
        language_DE:{
            correctAnswers:'Richtige Antworten',
            difficultQuestion:'Schwierig',
            mediumQuestion:'Mittel',
            easyQuestion:'Einfach'
        },
        language_EN:{
            correctAnswers:'Correct Answers',
            difficultQuestion:'Difficult',
            mediumQuestion:'Medium',
            easyQuestion:'Easy'
        },
        language_FR:{
            correctAnswers:'Réponses correctes',
            difficultQuestion:'Difficile',
            mediumQuestion:'Moyen',
            easyQuestion:'Facile'
        }
    }
    

    //++++++++++++++++STORE SELECTOR++++++++++++++++
    const currentQuestion = useSelector((state) => state.Question); //get the current question to get the image infos
    const selectedLanguage = useSelector((state) => state.Language); //get the selected language from the store. set within navigation component

    let levelOfDifficulty = componentContent[selectedLanguage].mediumQuestion
    const questionDifficulty = ((currentQuestion.numberOfCorrectAnswers / (currentQuestion.numberOfCorrectAnswers + currentQuestion.numberOfWrongAnswers)) * 100).toFixed(1)
    
    if(questionDifficulty >= 80){
        levelOfDifficulty = componentContent[selectedLanguage].easyQuestion
    } else if(questionDifficulty >= 50){
        levelOfDifficulty = componentContent[selectedLanguage].mediumQuestion
    } else {
        levelOfDifficulty = componentContent[selectedLanguage].difficultQuestion
    }
    
    //rendering the component
    return (
        <Grid container justifyContent="center" alignItems="center" textAlign="center">
            <Grid container maxWidth="lg" justifyContent="center" textAlign="center">
            <Grid item xs={12}>
                        <Button href={currentQuestion.sponsorInformation.sponsorLink}  target="_blank">
                            {currentQuestion.sponsorInformation.isSponsored ? 
                                    <div>
                                        <img src={currentQuestion.sponsorInformation.sponsorLogo} alt="sponsor Logo" style={{ height: '30px', }}/>
                                        <p style={{margin:0, color:"#F0CAA3", textTransform:"none"}}>{currentQuestion.sponsorInformation.sponsor}</p>
                                    </div>
                                
                                : ""}
                        </Button>
                </Grid>
                <Grid item md={6}  p={2} sx={{backgroundColor:"rgba(0,0,0,.7)", borderRadius:"10px"}}>
                    {!currentQuestion ? '' :
                        <div>                        
                        <Typography variant='h2'>
                            {currentQuestion[selectedLanguage].question}
                        </Typography>
                        
                        </div>
                    }
                </Grid>
                
                <Grid item xs={12}>
                    <Typography variant='caption'>
                        {levelOfDifficulty} ({componentContent[selectedLanguage].correctAnswers}: {questionDifficulty}%)
                    </Typography>
                </Grid>
            </Grid>
       </Grid>
    )

}

export default PlainQuestion