import axios from 'axios';
import { serverConnection } from '../constants/connection.js';

//set API-Endpoints of the server with the env-variable
const urlPlayQuiz = serverConnection +'/playquiz';
const urlQuestions = serverConnection +'/questions';
const urlPlayer = serverConnection  + '/player';

//export const addPlayer = (playerData) => axios.post(`${urlPlayer}/create`, playerData, {headers : { 'Authentification' : header }});
export const addPlayer = (playerData) => axios.post(`${urlPlayer}/create`, playerData);
export const changePlayerName = (playerData) => axios.patch(`${urlPlayer}/update`, playerData);

//playQuiz API-connection. playQuiz is also triggered via socket-connection to enable the server-client communication
//API-function are only used for init commands
//new playQuiz. No login required, everybody can host a quiz. Executed to start/host a quiz
export const createPlayQuiz = (newPlayQuiz) => axios.post(urlPlayQuiz, newPlayQuiz);
//get the playQuiz from the database to receive the latest data. Executed everytime a player joins the game.
export const getPlayQuiz = (quizId) => axios.get(`${urlPlayQuiz}/${quizId}`);

//question API-connection
//get only one question from the database. Not all fields are retrieved to protect the answers. Is used during the quiz
export const getOneQuestion = (_id) => axios.get(`${urlQuestions}/getOne/${_id}`);

