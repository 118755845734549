import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import md5 from 'md5';
import { Grid, Typography, LinearProgress, Box } from '@mui/material';

import { endPlayQuizRound, countdownTimer } from '../../../../actions/playQuiz.js';

//Timer is used within an active play
//shows at the top of the page and runs only when the question is active
//when question isn't active or the time is up, the logo is displayed
const Timer = () => {
    
    //++++++++++++++++++++VARIABLES+++++++++++++++++++++++
    const dispatch = useDispatch();

    //++++++++++++++++++++STORE SELECTOR+++++++++++++++++++++++
    const playerId = useSelector((state) => state.Cookie.playerId); //used to check if the player is the host
    const currentQuestion = useSelector((state) => state.Question); //gets the current question of the playQuiz (for timer infos needed)
    const playQuiz = useSelector((state) => state.PlayQuiz); //gets the complete playQuiz data (for navigation purpose needed)

    let timer = useSelector((state) => state.Timer.timeLeft)//gets the complete playQuiz data (for navigation purpose needed)
    
    if(!timer && timer !== 0){
        timer = playQuiz.currentRound.timeLeft;
    }
    //++++++++++++++++++++VARIABLES BASED ON STORE+++++++++++++++++++++++
    const playQuizNavQuestion = playQuiz.navigation.currentQuestion;
    const playQuizCurrentQuestion = playQuiz.questions[playQuizNavQuestion]; //get the current question ID
    const startTime = playQuiz.currentRound.timeLeftInit; //get the initial time for the round - this value doesn't change
    const timeStepper = 100 / startTime; //calculate the stepper for displaying as a cirlce (100 = always full cirlce)

    //++++++++++++++++++++STATE DEFINTION+++++++++++++++++++++++
    const [timeLeft, setTimeLeft] = useState(timer); //state to track the timer

    //++++++++++++++++++++USE EFFECT EVENTS+++++++++++++++++++++++
    //init the timer value
    //useEffect(() => {
    //    setTimeLeft(timer);
    //},[timer])
    
    //timer function to set the display and the timer every second to the next value
    //end the round if the time is up (only the host "can" end the round to prevent double endings - otherwise every timer of every player would end the round)
    useEffect(() => {
        const timer = setTimeout(() => {
            if(!playQuiz.currentRound.currentRoundIsActive){
                setTimeLeft(0);
            }else if(timeLeft >= 0){
                setTimeLeft(timeLeft-1);
                if(playQuiz.settings.hostId === md5(playerId)){
                    dispatch(countdownTimer(timeLeft)) //reduce timer in db, only for host to avoid double insert
                }
                
            }else if (timeLeft === -1 && playQuiz.settings.hostId === md5(playerId)){
                dispatch(endPlayQuizRound(playQuizCurrentQuestion.questionId, currentQuestion.settings.answerType))
            }
        }, 1000);

        return () => clearTimeout(timer);
        
    })
    //rendering the timer
    //displayed at the top of the screen
    return (
        <Grid container justifyContent="center" textAlign="center" alignContent="center">
            <Grid container maxWidth="lg" justifyContent="center" textAlign="center" alignContent="center" p={1}>
            <Box sx={{ width: '100%' }}>
                <LinearProgress variant="determinate" value={100 - (timeStepper * (startTime - timer))} sx={{backgroundColor:"#00000000"}} />
            </Box>
            <Typography variant='h3'>{timer}</Typography>
            </Grid>
        </Grid>
    )
}

export default Timer


