import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import md5 from 'md5';

import { navigatePlayQuiz, createPlayQuizNewRound } from '../../../actions/playQuiz.js';
import { start, question, end, results, countdown } from '../../../constants/quizStatus.js';
import { clearQuestion } from '../../../actions/questions.js';

//The HostNavButton is used to navigate through a playQuiz
//only the host of the playQuiz can see the button
//the action depends on the status of the quiz
const HostNavButton = () => {

    //++++++++++++++++++++++VARIABLES+++++++++++++++++++++++
    const dispatch = useDispatch();
    let buttonLabel;

    //every text is stored within this constant
    //translation are done here - just enter a different language
    const componentContent = {
        language_DE:{
            buttonLabelStart: 'Quiz Starten',
            buttonLabelResults: 'Weiter',
            buttonLabelQuestion: 'Nächste Frage',
            buttonLableStartNewQuiz: 'Neues Quiz Starten'
        },
        language_EN:{
            buttonLabelStart: 'Start Quiz',
            buttonLabelResults: 'Next',
            buttonLabelQuestion: 'Next Question',
            buttonLableStartNewQuiz: 'Start New Quiz'
        },
        language_FR:{
            buttonLabelStart: 'Commencer le quiz',
            buttonLabelResults: 'Suivant',
            buttonLabelQuestion: 'Question suivante',
            buttonLableStartNewQuiz: 'Commencer un nouveau quiz'
        }
    }

    //+++++++++++++++++++++STORE SELECTOR++++++++++++++++++++
    const playerId = useSelector((state) => state.Cookie.playerId); //get the cookie of the player to evaluate if the player is host
    const playerName = useSelector((state) => state.Cookie.playerName); //get the cookie of the player to evaluate if the player is host
    const playQuiz = useSelector((state) => state.PlayQuiz); //get the current playQuiz to push the status to the functions
    const selectedLanguage = useSelector((state) => state.Language); //get the selected language from the store. set within navigation component
    
    //depending on the status of the playQuiz the buttonLabel changes
    switch(playQuiz.navigation.currentType){
        case start:
            buttonLabel = playQuiz.players.filter(player => player.playerSessionIsActive === true).length + " Player " + componentContent[selectedLanguage].buttonLabelStart;
        break;
        case question:
            buttonLabel = componentContent[selectedLanguage].buttonLabelResults;
        break;
        case results:
            buttonLabel = componentContent[selectedLanguage].buttonLabelQuestion;
        break;
        case end:
            buttonLabel = componentContent[selectedLanguage].buttonLableStartNewQuiz;
        break;
        default:
            buttonLabel = componentContent[selectedLanguage].buttonLabelStart;
    }

    //+++++++++++++++++++++FUNCTIONS++++++++++++++++++++
    //change the playQuiz status to the next status
    //parameters are sent to server to change the db entry of the playQuiz
    //actions are pushed with webSocket to all clients
    //_id: what playQuiz needs to be updated
    //currentType: What is the currentType (used to check if an init of the points is needed)
    //next Type: countdown, end, results... sets the next playQuiz status
    //currentQuestion sets the current question. The next question is set within the QuestionCountdown container. The countdown is a 3-Sec countdown before every question
    //created At: Used to calculate the time from beginning to end (average play time)
    //origin ID: origin play ID to write the average playtime into the quiz object
    const handleClick = (e) => {
        e.preventDefault();
        switch(playQuiz.navigation.currentType){
            case start:
                dispatch(navigatePlayQuiz(playQuiz._id, playQuiz.navigation.currentType, countdown, playQuiz.navigation.currentQuestion, playQuiz.createdAt));
            break;
            case question:
                if((playQuiz.questions.length - 1) <= playQuiz.navigation.currentQuestion){
                    dispatch(clearQuestion());
                    dispatch(navigatePlayQuiz(playQuiz._id, playQuiz.navigation.currentType, end, playQuiz.navigation.currentQuestion, playQuiz.createdAt));
                }else{
                    dispatch(clearQuestion());
                    dispatch(navigatePlayQuiz(playQuiz._id, playQuiz.navigation.currentType, results, playQuiz.navigation.currentQuestion, playQuiz.createdAt));
                }
            break;
            case results:
                    dispatch(navigatePlayQuiz(playQuiz._id, playQuiz.navigation.currentType, countdown, playQuiz.navigation.currentQuestion, playQuiz.createdAt));
            break;
            case end:
                    dispatch(createPlayQuizNewRound({hostId: playerId, playerId: playerId, playerName:playerName}));
            break;
            default:
                dispatch(navigatePlayQuiz(playQuiz._id, playQuiz.navigation.currentType, start, playQuiz.navigation.currentQuestion, playQuiz.createdAt));
        }
    }

    if(md5(playerId) === playQuiz.settings.hostId && !playQuiz.currentRound.currentRoundIsActive){ //player is a host
        return (
            <Button variant="contained" onClick={handleClick}>
                { buttonLabel }
            </Button>
        )
    }else{ //player is not a host
        return <div></div>
    }
}

export default HostNavButton;