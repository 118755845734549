import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Button, TextField, Stack } from '@mui/material';

import '../../../App.css';
import { getPlayQuizAndOpen } from '../../../actions/playQuiz';
import Language from '../../Language/Language';


const Join = () => {
    //Variables
    const dispatch = useDispatch();
    const selectedLanguage = useSelector((state) => state.Language); //get the selected language from the store. set within navigation component

    //every text is stored within this constant
    //translation are done here - just enter a different language
    const componentContent = {
        language_DE:{
            validationMessageMissingPIN:'Bitte PIN eingeben',
            buttonLabelSubmit:'Beitreten',
            inputLabelPIN:'Bitte PIN eingeben',
            backButton:'Zurück'
        },
        language_EN:{
            validationMessageMissingPIN:'Please enter a PIN',
            buttonLabelSubmit:'Join',
            inputLabelPIN:'Please enter a PIN',
            backButton:'Back'
        },
        language_FR:{
            validationMessageMissingPIN:'Veuillez entrer un code PIN',
            buttonLabelSubmit:'Rejoindre',
            inputLabelPIN:'Veuillez entrer un code PIN',
            backButton:'Précédent'
        }
    }

    //++++++++++++++++STATE SETTINGS++++++++++++++++++
    const [quizid, setPin] = useState(0);

    //+++++++++++++++++FUCNTIONS++++++++++++++++++++ 
    //handle the PIN Input
    const handleChangePinInput = (pinInput) => {
        setPin(pinInput)
    }
    //handle the submit event and check if the PIN is correct
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(getPlayQuizAndOpen(quizid));
    }

    return(
        <Grid container justifyContent="center" alignItems="center" textAlign="center" sx={{height: '100vH'}}>
            <Grid container maxWidth="lg" justifyContent="center" alignItems="center">
            <Language/>
                <Stack spacing={2}>
                    <form autoComplete='off' noValidate onSubmit={handleSubmit}>
                        <TextField 
                            placeholder={componentContent[selectedLanguage].inputLabelPIN}
                            variant="outlined" 
                            autoFocus  
                            sx={{backgroundColor:"lightgrey"}}
                            onChange={(e) => handleChangePinInput(e.target.value)} 
                        />
                    </form>
                    <Button variant="contained" size="large" onClick={handleSubmit}>{componentContent[selectedLanguage].buttonLabelSubmit}</Button>
                    <Button variant='link' href='/'>{componentContent[selectedLanguage].backButton}</Button>
                </Stack>
            </Grid>
        </Grid>
    )

}

export default Join