//defines all action types for the reducers
export const CREATE_PLAY_QUIZ = 'CREATE_PLAY_QUIZ';
export const GET_PLAY_QUIZ = 'GET_PLAY_QUIZ';
export const PLAYER_LEAVE = 'PLAYER_LEAVE';

export const SET_COOKIE = 'SET_COOKIE';

export const GET_ALL_QUESTIONS = 'GET_ALL_QUESTIONS';
export const GET_ONE_QUESTION = 'GET_ONE_QUESTIONS';
export const SET_CURRENT_QUESTION = 'SET_CURRENT_QUESTION';
export const CLEAR_QUESTION = 'CLEAR_QUESTION';

export const SET_LANGUAGE = 'SET_LANGUAGE';

export const SET_RESPONSE = 'SET_RESPONSE';

export const SET_TIMER = 'SET_TIMER';