import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import md5 from 'md5';
import { Grid, Button, TextField, Stack } from '@mui/material';

import '../../../App.css';
import { addPlayer } from '../../../actions/player';
import { addPlayerToQuiz } from '../../../actions/playQuiz';
import Language from '../../Language/Language';


const EnterName = () => {
    const dispatch = useDispatch();

    //every text is stored within this constant
    //translation are done here - just enter a different language
    const componentContent = {
        language_DE:{
            validationMessageMissingName:'Bitte Name eingeben',
            buttonLabelSubmit:'Start',
            inputLabelName:'Gebe deinen Namen ein',
            backButton:'Zurück'
        },
        language_EN:{
            validationMessageMissingName:'Please enter a name',
            buttonLabelSubmit:'Start',
            inputLabelName:'Enter your name',
            backButton:'Back'
        },
        language_FR:{
            validationMessageMissingName:'Veuillez entrer un nom',
            buttonLabelSubmit:'Commencer',
            inputLabelName:'Entrez votre nom',
            backButton:'Précédent'
        }
    }

    //++++++++++++++++STORE SETTINGS++++++++++++++++++
    const playQuiz = useSelector((state) => state.PlayQuiz); //get the current player name based on the cookie
    const selectedLanguage = useSelector((state) => state.Language);

    //++++++++++++++++STATE SETTINGS++++++++++++++++++
    const [validationMessage, setvalidationMessage] = useState(''); //Validation message to check if name input is filled
    const [playerData, setNewPlayer] = useState(  //State which is sent to db
        {
            playerId: '',
            playerName:'',
        }
    );

    //+++++++++++++++++FUCNTIONS++++++++++++++++++++ 
    //handle the name change and convert the id (later the cookie) to md5 hash
    const handleChangeNameInput = (newName) => {
        setNewPlayer(
            {
                playerName: newName, 
                playerId: md5(newName + Date())
            }
        )
        setvalidationMessage('')
    }


    //handle the submit event and link to Lobby
    const handleSubmit = (e) => {
        e.preventDefault();
        if(playerData.playerName){
            dispatch(addPlayer(playerData));
            dispatch(addPlayerToQuiz(playQuiz._id,playerData,playQuiz.settings.hostId));
        }else{
            setvalidationMessage(componentContent[selectedLanguage].validationMessageMissingName);
        }   
    };

    return(
        <Grid container justifyContent="center" alignItems="center" textAlign="center" sx={{height: '100vH'}}>
            <Grid container maxWidth="lg" justifyContent="center" textAlign="center">
                <Language/>
                <Stack spacing={2}>
                    <form autoComplete='off' noValidate onSubmit={handleSubmit}>
                        <TextField 
                            placeholder={componentContent[selectedLanguage].inputLabelName}
                            variant="outlined" 
                            autoFocus 
                            sx={{backgroundColor:"lightgrey"}}
                            onChange={(e) => handleChangeNameInput(e.target.value)} 
                        />
                        <div>{validationMessage}</div>
                    </form>
                    <Button variant="contained" size="large" onClick={handleSubmit}>{componentContent[selectedLanguage].buttonLabelSubmit}</Button>
                    <Button variant='link' href='/'>{componentContent[selectedLanguage].backButton}</Button>
                </Stack>
            </Grid>
        </Grid>
    )

}

export default EnterName