import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import md5 from 'md5';
import { Button, Grid, Badge } from '@mui/material';

import '../../../../App.css';
import { answerPlayQuizQuestion } from '../../../../actions/playQuiz';


const MCAnswers = () => {
    //++++++++++++++++VARIABLES++++++++++++++++
    const startTime = new Date().getTime(); //get the time on render to calculate the needed time for this question
    const dispatch = useDispatch();

    const componentContent = {
        language_DE:{
            sourceText:'Details zur Antwort findest du hier'
        },
        language_EN:{
            sourceText:'You find answer details here'
        },
        language_FR:{
            sourceText:'Détails de la réponse ici'
        }
    }

    //++++++++++++++++STORE SELECTOR++++++++++++++++
    const playerId = useSelector((state) => state.Cookie.playerId); //use the current cookie to filter the results of each player
    const currentQuestion = useSelector((state) => state.Question); //get the current question to get the image infos
    const playQuiz = useSelector((state) => state.PlayQuiz);
    const selectedLanguage = useSelector((state) => state.Language); //get the selected language from the store. set within navigation component
    
    const playQuizNavQuestion = playQuiz.navigation.currentQuestion;
    const playQuizCurrentPlayer = playQuiz.players.filter(player => player.playerId === md5(playerId) )[0]; //get the current player of the playQuiz (e.g. points)
    const playQuizCurrentQuestion = playQuiz.questions[playQuizNavQuestion]; //get the current question ID

    //++++++++++++++++STATE SETTINGS++++++++++++++++++
    const [buttonsAreDisabled, setbuttonsAreDisabled] = useState(playQuizCurrentPlayer.playerHasAnswered) //set the state of the answer buttons after answer is given. If the player has answered, the answer is disabled
    const [playersAnswerId, setPlayersAnswerId] = useState(''); //set the given answer of the player

    //++++++++++++++++USE EFFECT EVENTS++++++++++++++++
    //disable the buttons when the player has answered
    useEffect(() => {
        setbuttonsAreDisabled(playQuizCurrentPlayer.playerHasAnswered);
        setbuttonsAreDisabled(!playQuiz.currentRound.currentRoundIsActive);
    },[playQuizCurrentPlayer.playerHasAnswered,playQuiz.currentRound.currentRoundIsActive])

    //+++++++++++++++++FUCNTIONS++++++++++++++++++++
    //handle the submit event. A player selects an answer
    //the time is calculated and the answer is sent to the server
    //the answer type is also set to give the correct points for this answer (every answertype is different)
    const handleSubmitAnswer = (givenAnswerId) => {
        const endTime = new Date().getTime();
        const neededTime = endTime - startTime;
        dispatch(answerPlayQuizQuestion(playQuizCurrentQuestion.questionId, currentQuestion.settings.answerType, givenAnswerId, neededTime, currentQuestion.timerSec, playerId, playQuiz.quizId,selectedLanguage,playQuizCurrentPlayer.playerAnswerStrike)); //player Id is set within socket
        setbuttonsAreDisabled(true);
        setPlayersAnswerId(givenAnswerId);
    }

    return(
        <Grid container justifyContent="center" alignItems="center" textAlign="center" >
            <Grid container maxWidth="lg" spacing={1} p={2} alignItems="center" textAlign="center" justifyContent="center">
                {!currentQuestion ? "" :
                    currentQuestion[selectedLanguage].answers.map((answer, answerId) => (
                        <Grid item xs={12} md={6} key={answerId}>
                            <Badge // eslint-disable-next-line 
                                badgeContent={!playQuiz.currentRound.currentRoundIsActive ? playQuiz.currentRound.answers.filter(answer => answer == answerId).length : 0} 
                                color='primary'
                                sx={{width:'100%'}}
                            >
                                <Button
                                    fullWidth
                                    variant='contained'
                                    size="large"
                                    key={answerId}
                                    disabled = {buttonsAreDisabled}
                                    style={{border:answerId === playersAnswerId ? "3px solid orange" :"" ,// eslint-disable-next-line 
                                            backgroundColor: playQuiz.currentRound.correctAnswer == answerId && !playQuiz.currentRound.currentRoundIsActive ? "green" : ""
                                        }}
                                    onClick={(e) => {handleSubmitAnswer(answerId)}}
                                >
                                    {answer}
                                </Button>
                            </Badge>
                        </Grid>
                    ))
                }
                <div>
                    {!playQuiz.currentRound.currentRoundIsActive ?
                        <Button color='secondary' size='large' variant='text' href={currentQuestion.source} target="_blank">
                            {componentContent[selectedLanguage].sourceText}
                        </Button> 
                    : ""}
                </div>
            </Grid>
        </Grid>
    )

}

export default MCAnswers