import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Cookies from 'universal-cookie';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from '@mui/material';

import './App.css';
import { changePlayerCookie } from './actions/player.js';
import Home from './components/Home/Home.js';
import Play from './components/Play/Play.js';
import { playerCookie } from './constants/cookies';
import { SET_COOKIE } from './constants/actionTypes';
import Impressum from './components/Impressum/Impressum.js';
import ConsentBanner from './components/ConsentBanner/ConsentBanner.js';

const App = () => {
    
  //Variablies
  const dispatch = useDispatch();

  //create new Cookie Object and get the potential cookie from the player
  const cookies = new Cookies();
  let playerCookieValue = cookies.get(playerCookie);
  if(playerCookieValue){
      dispatch({ type: SET_COOKIE, payload: playerCookieValue }); //if a cookie is already stored in the browser, dispatch it to the store. Otherwise the cookie is dispatched by JOIN oder ADD Player
      dispatch(changePlayerCookie(playerCookieValue))
  }

  return (
      <Container disableGutters = {true} maxWidth = {false} sx={{height: '100vH'}}>
        <ConsentBanner/>
        <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route exact path="/play" element={<Play/>} />
          <Route exact path="/impressum" element={<Impressum/>} />
        </Routes>
        
      </Container>
  );

}

export default App;
