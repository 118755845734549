import React, {useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Typography  } from '@mui/material';

import { getOneQuestion } from '../../../actions/questions';
import HostNavButton from '../HostNavButton/HostNavButton';
import PlainQuestion from './Question/PlainQuestion';
import MCAnswers from './Answers/MCAnswers';
import Timer from './Timer/Timer';
import Language from '../../Language/Language';

const Quiz = () => {
    
    const dispatch = useDispatch();

    const componentContent = {
        language_DE:{
            question:'Frage'
        },
        language_EN:{
            question:'Question'
        },
        language_FR:{
            question:'Question'
        }
    }
    
    //++++++++++++++++STORE SELECTOR++++++++++++++++
    const playQuiz = useSelector((state) => state.PlayQuiz);
    const currentQuestion = useSelector((state) => state.Question); //get the currentquestion to show the sub components only when the question is ready
    const selectedLanguage = useSelector((state) => state.Language); //get the selected language from the store. set within navigation component

    const playQuizNavQuestion = playQuiz.navigation.currentQuestion;
    const playQuizCurrentQuestion = playQuiz.questions[playQuizNavQuestion]; //get the current question ID - this id is used to grab the question data object from db

    //++++++++++++++++USE EFFECT EVENTS++++++++++++++++
    useEffect(() => {
        if(!currentQuestion){
            dispatch(getOneQuestion(playQuizCurrentQuestion.questionId)); //get the question according to the questionId within playQuiz and push it to the store
        }   
    });
    
    return(
        <Grid container justifyContent="center" alignItems="center" textAlign="center" sx={{height: '100vH'}}>
            {!currentQuestion ? '' :
            <Grid container justifyContent="center" alignItems="center" textAlign="center">
                <Language />
                <Grid container mb={1}>
                    {(() => {
                        switch (currentQuestion.settings.questionType) {
                            //case "image": return <ImageQuestion />;
                            case "plain": return <PlainQuestion />;
                            default: return <PlainQuestion />;
                        }
                    })()}
                    
                </Grid>
                <Timer/>
                <Grid container>
                    {(() => {
                        switch (currentQuestion.settings.answerType) {
                            case "MC": return <MCAnswers />;
                           // case "action": return <ActionAnswer />;
                           // case "guess": return <GuessingAnswer />;
                             default: return <MCAnswers />;
                        }
                    })()}
                </Grid>
                <HostNavButton/>
                <Grid container textAlign="center" alignContent="center" justifyContent='center' >
                    <Typography variant='overline'>{componentContent[selectedLanguage].question} {playQuiz.navigation.currentQuestion +1 }/{playQuiz.questions.length} | QUIZ: {playQuiz.quizId}</Typography>
                </Grid>
            </Grid>
            }
        </Grid>
        
    )
}

export default Quiz