import React, {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Dialog, Typography, Switch, FormGroup, FormControlLabel, ButtonGroup, Button } from '@mui/material';
import Cookies from 'universal-cookie';
import { allowTracking, denyTracking } from '../../actions/consentCookie';


const ConsentBanner = () => {

    //Variables
    const dispatch = useDispatch();

    //get the player Consent cookie
    const cookies = new Cookies();
    let playerConsent = cookies.get("playerConsent");

    const [dialogState, setDialog] = useState(false) //hide the dialog at start

    //on start - open the dialog if no consent is set or push the ga script to the header
    useEffect(() => {
        if(playerConsent === "allow"){
            setTrackingHeader();
        } else if(!playerConsent){
            setDialog(true);
        }
    }, [setDialog, playerConsent])

    //function to push the ga script to the header
    const setTrackingHeader = () => {
        const s1 = document.createElement('script');
        s1.async = true;
        s1.src = "https://www.googletagmanager.com/gtag/js?id=G-SGYY0P3MEL"

        var firstScript = document.getElementsByTagName('script')[0];
        firstScript.parentNode.insertBefore(s1,firstScript);
    }
    
    //confirm the dialog and close it
    const confirmDialog = (playerChoice) => {
        if(playerChoice){
            dispatch(allowTracking());
            setDialog(false);
        } else {
            dispatch(denyTracking());
            setDialog(false);
        }
    }

    return(
        <Dialog  open={dialogState}>
            <Grid container padding={2} sx={{backgroundColor:"lightgrey"}}>
                <Grid item xs={12}>
                    <Typography color="black">
                       This website uses Cookies
                    </Typography>
                    <Typography fontSize="small" color="black">
                        If you are under 16 years old and wish to consent to optional services, you must ask your legal guardians for permission.
                        We use cookies on our website. Some of them are essential, while others help us improve this website and your experience. 
                        Personal data may be processed (e.g., IP addresses) for purposes such as website activity analysis. 
                        For more information on how we use your data, please refer to our Privacy Policy. 
                        You are not required to consent to the processing of your data in order to use this service. 
                        You can withdraw or adjust your preferences at any time in the settings.
                    </Typography>
                    <FormGroup>
                        <FormControlLabel  disabled control={<Switch defaultChecked />} label={<Typography fontSize="small" color="black">Necessary</Typography>}/>
                        <FormControlLabel  control={<Switch />} label={<Typography fontSize="small" color="black">Statistics</Typography>}/>
                    </FormGroup>
                    <ButtonGroup>
                        <Button variant='contained' onClick={(e) => confirmDialog(true)}>Confirm all</Button>
                        <Button onClick={(e) => confirmDialog(false)} >Save</Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
        </Dialog>
    )

}

export default ConsentBanner