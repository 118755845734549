import React, {useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@mui/material';

import { getOneQuestion } from '../../../actions/questions';
import QuestionCountdown from './QuestionCountdown';
import QuestionCountdownSponsor from './QuestionCountdownSponsor';

const QuestionCountdownContainer = () => {

const dispatch = useDispatch();

const playQuiz = useSelector((state) => state.PlayQuiz); //get the current player name based on the cookie

const playQuizNavQuestion = playQuiz.navigation.currentQuestion + 1;
const playQuizCurrentQuestion = playQuiz.questions[playQuizNavQuestion]; //get the current question ID - this id is used to grab the question data object from db
//++++++++++++++++USE EFFECT EVENTS++++++++++++++++
useEffect(() => {
        dispatch(getOneQuestion(playQuizCurrentQuestion.questionId)); //get the question according to the questionId within playQuiz and push it to the store
});

return ( 
    <Grid container justifyContent="center" alignItems="center" textAlign="center" sx={{height: '100vH'}}>
        <Grid container maxWidth="lg" justifyContent="center" textAlign="center" spacing={3}>
            <Grid item xs={12}>
                <QuestionCountdownSponsor/>
            </Grid>
            <Grid item xs={12}>
                <QuestionCountdown/>
            </Grid>
        </Grid>
    </Grid>
)
}

export default QuestionCountdownContainer