import { SET_LANGUAGE, SET_COOKIE, PLAYER_LEAVE, GET_PLAY_QUIZ, CREATE_PLAY_QUIZ, SET_RESPONSE, SET_CURRENT_QUESTION, GET_ONE_QUESTION, CLEAR_QUESTION, SET_TIMER } from '../constants/actionTypes.js';
 
//export all relevant reducers
//most of the time the logic sets the store only with the payload without any logic
export const Cookie = (cookie = '', action) => {
    switch (action.type) { 
        case SET_COOKIE:
            return action.payload; 
        default:
            return cookie;
    }
}

export const Language = (language = 'language_EN', action) => {
    switch (action.type) { 
        case SET_LANGUAGE:
            return action.payload; 
        default:
            return language;
    }
}

export const PlayQuiz = (quiz = '', action) => {
    switch (action.type) { 
        case CREATE_PLAY_QUIZ:
        case GET_PLAY_QUIZ:
        case PLAYER_LEAVE:
            return action.payload; 
        default:
            return quiz;
    }
}

export const Question = (question = '', action) => {
    switch (action.type) { 
        case SET_CURRENT_QUESTION:
        case GET_ONE_QUESTION:
            return action.payload; 
        case CLEAR_QUESTION:
            return '';
        default:
            return question;
    }
}


export const ServerResponse = (response = true, action) => {
    switch (action.type) { 
        case SET_RESPONSE:
            return action.payload;
        default:
            return response;
    }
}

export const Timer = (timer = 0, action) => {
    switch (action.type) { 
        case SET_TIMER:
            return action.payload;
        default:
            return timer;
    }
}