import { SET_COOKIE } from '../constants/actionTypes.js';
import { playerCookie } from '../constants/cookies.js';
import * as api from '../api/index.js';
import Cookies from 'universal-cookie'; 
 
const cookies = new Cookies();


//The players actions are designed to save players information
//data like nickname, last seen or correct and wrong answers are stored
//a cookie is set to connect the browser with the data

//++++++++++++++++++ADD PLAYER+++++++++++++++++++++++
export const addPlayer = (playerData) => async (dispatch) => {
  
  try {
    //send player data to the server to create a new player item   
    const { data } = await api.addPlayer(playerData);

    //if item was created create cookie and dispatch the playerData to the store
    if(data.error){
      console.log(data.message);
    }else{
      cookies.set( playerCookie, playerData, { path: '/',maxAge: 95000000000 , sameSite: 'strict', secure: true  });
      dispatch({ type: SET_COOKIE, payload: playerData });
    }

    //catch error block
    } catch (error) {
      console.log(error);
    }
}

//++++++++++++++++++ADD PLAYER+++++++++++++++++++++++
export const changePlayerName = (playerData) => async (dispatch) => {
  
  try {
    //send player data to the server to create a new player item   
    const { data } = await api.changePlayerName(playerData);

    //if item was created create cookie and dispatch the playerData to the store
    if(data.error){
      console.log(data.message);
    }else{
      cookies.set( playerCookie, playerData, { path: '/',maxAge: 95000000000 , sameSite: 'strict', secure: true  });
      dispatch({ type: SET_COOKIE, payload: playerData });
    }

    //catch error block
    } catch (error) {
      console.log(error);
    }
}

//++++++++++++++++++ADD PLAYER+++++++++++++++++++++++
export const changePlayerCookie = (playerData) => async (dispatch) => {
  
    cookies.set( playerCookie, playerData, { path: '/',maxAge: 95000000000, sameSite: 'strict', secure: true  });
    dispatch({ type: SET_COOKIE, payload: playerData });

}