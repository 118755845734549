import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';
import { createTheme, ThemeProvider } from '@mui/material';

import reducers from './reducers'; //reducers 
import './index.css';
import App from './App';
import addSocketListeners from './api/socketListener.js';
import reportWebVitals from './reportWebVitals';
import * as colors from './constants/styleValues.js'

const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = createTheme({
  palette: {
      primary: {
          main: colors.COLOR_PRIMARY
      },
      secondary: {
          main: colors.COLOR_SECONDARY
      },
      action: {
        disabledBackground: '#6B2B56',
        disabled: 'grey'
      }
  },
  typography: {
    allVariants: {
      color: colors.COLOR_FONT_PRIMARY
    },
    caption: {
        fontStyle: "italic"
    },
    h1: {
        "@media (min-width:601px)": {
            fontSize: "3.5rem",
        },
        "@media (max-width:600px)": {
            fontSize: "2.5rem",
        }
    },
    h2: {
        "@media (min-width:601px)": {
            fontSize: "2rem",
        },
        "@media (max-width:600px)": {
            fontSize: "1.3rem",
        }
    },
    h3: {
        "@media (min-width:601px)": {
            fontSize: "1.5rem",
        },
        "@media (max-width:600px)": {
            fontSize: "1.1rem",
        }
    },
    h4: {
        "@media (max-width:400px)": {
            fontSize: "0.9rem",
        },
        "@media (min-width:401px)": {
            fontSize: "1.1rem",
        },
        "@media (min-width:601px)": {
          fontSize: "1.5rem",
      }
    }
  }
});


const store = createStore(reducers, applyMiddleware(thunk)); //stores all central states and can be accessed via useSelector

//adds the socket listener to the app to listen to the socket emit events from the server 
//https://community.risingstack.com/handling-outside-events-with-the-redux-listener-pattern/
addSocketListeners(store.dispatch, store.getState);

root.render(

    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <App />
        </Router>
      </ThemeProvider> 
    </Provider>
    
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();