import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import md5 from 'md5';
import { Typography  } from '@mui/material';
import { keyframes } from '@mui/system';

import { question } from '../../../constants/quizStatus.js';
import { navigatePlayQuiz } from '../../../actions/playQuiz.js';

 //question countdown is shon before every question
 //a 3 seconds countdown runs, after the time is up the playQuiz navigation is triggered
const QuestionCountdown = () => {
    //++++++++++++++++VARIABLES++++++++++++++++
    const dispatch = useDispatch();
    const grow = keyframes({
        "0%": {
          fontSize:"10vw"
        },
        "100%": {
            fontSize:"20vw"
        }
      });

    //++++++++++++++++STATE SETTINGS++++++++++++++++++
    const [timeLeft, setTimeLeft] = useState(3); //set the timer to 3 seconds

    //++++++++++++++++STORE SELECTOR++++++++++++++++
    const playerId = useSelector((state) => state.Cookie.playerId); //get the current Cookie to check if the player is host
    const playQuiz = useSelector((state) => state.PlayQuiz); //get the current playQuiz to navigate to the next question
    const currentQuestion = useSelector((state) => state.Question); //get the current question to get the image infos
    
    //++++++++++++++++USE EFFECT EVENTS++++++++++++++++
    //set the timer. when the time is up and the player is host (without host no navigation) the next question comes
    //check out HostNavButton Component to get more infos about the function navigatePlayQuiz
    useEffect(() => {
        const timer = setTimeout(() => {
            if(timeLeft > 0){
                setTimeLeft(timeLeft-1);
            }else if (timeLeft === 0 && playQuiz.settings.hostId === md5(playerId)){
                dispatch(navigatePlayQuiz(playQuiz._id, playQuiz.navigation.currentType, question, playQuiz.navigation.currentQuestion + 1, playQuiz.createdAt, playQuiz.originId));
            }
        }, 1000);

        return () => clearTimeout(timer);
        
    })
    //rendering
    return(
        currentQuestion ?
            currentQuestion.sponsorInformation.isSponsored ?
                <Typography variant='h2'>{timeLeft}</Typography>
                :
                <Typography variant='h1' sx={{animation: `${grow} 1s 4`}}>{timeLeft}</Typography>
            :
            ""
    )

}

export default QuestionCountdown;