import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Button, TextField, Typography, Stack } from '@mui/material';

import { changePlayerName } from '../../actions/player';
import { createPlayQuiz } from '../../actions/playQuiz';
import Language from '../Language/Language';


const ChooseMode = () => {

    //Variables
    const dispatch = useDispatch();
    
    //every text is stored within this constant
    //translation are done here - just enter a different language
    const componentContent = {
        language_DE:{
            welcomeMessage:['Willkommen!','Hallo!','Wundervollen Guten Tag!','Herzlich Willkommen!','Grüezi!','Salut!','Bienvenue!','Ciao!','Howdy!','Aloha!','Namaste!','Hola!','Guten Morgen!','Servus!','Hi!','Moin!','Good day!','Buongiorno!','Hej!','Shalom!'],
            welcomeName:['Clever Cat','Quiz Whiz','Smarty Pants','Brainy Bunny','Quiz Master','Genius Giraffe','Smart Cookie','Quiz King/Queen','Brain Buff','Trivia Titan','Knowledge Ninja','Wise Owl','Brainiac Bob/Bella','Quiz Quokka','IQ Iguana','Professor Puzzle','Trivia Tiger','Clever Cucumber','Quiz Wizard','Smarty Shark'],
            createQuiz:'Neues Quiz starten',
            joinQuiz:'Quiz beitreten',
            changeName:'Name ändern',
            saveName:'Name speichern',
            privacy:'Datenschutzerklärung',
            partner:'Unsere Partner'
        },
        language_EN:{
            welcomeMessage:['Willkommen!','Hallo!','Wundervollen Guten Tag!','Herzlich Willkommen!','Grüezi!','Salut!','Bienvenue!','Ciao!','Howdy!','Aloha!','Namaste!','Hola!','Guten Morgen!','Servus!','Hi!','Moin!','Good day!','Buongiorno!','Hej!','Shalom!'],
            welcomeName:['Clever Cat','Quiz Whiz','Smarty Pants','Brainy Bunny','Quiz Master','Genius Giraffe','Smart Cookie','Quiz King/Queen','Brain Buff','Trivia Titan','Knowledge Ninja','Wise Owl','Brainiac Bob/Bella','Quiz Quokka','IQ Iguana','Professor Puzzle','Trivia Tiger','Clever Cucumber','Quiz Wizard','Smarty Shark'],
            createQuiz:'Start new Quiz',
            joinQuiz:'Join Quiz',
            changeName:'Change Name',
            saveName:'Save Name',
            privacy:'Privacy Statement',
            partner:'Our Partners'
        },
        language_FR:{
            welcomeMessage:['Willkommen!','Hallo!','Wundervollen Guten Tag!','Herzlich Willkommen!','Grüezi!','Salut!','Bienvenue!','Ciao!','Howdy!','Aloha!','Namaste!','Hola!','Guten Morgen!','Servus!','Hi!','Moin!','Good day!','Buongiorno!','Hej!','Shalom!'],
            welcomeName:['Clever Cat','Quiz Whiz','Smarty Pants','Brainy Bunny','Quiz Master','Genius Giraffe','Smart Cookie','Quiz King/Queen','Brain Buff','Trivia Titan','Knowledge Ninja','Wise Owl','Brainiac Bob/Bella','Quiz Quokka','IQ Iguana','Professor Puzzle','Trivia Tiger','Clever Cucumber','Quiz Wizard','Smarty Shark'],
            createQuiz:'Créer un quiz',
            joinQuiz:'Rejoindre un quiz',
            changeName:'Changer le nom',
            saveName:'Enregistrer le nom',
            privacy:'Privacy Statement',
            partner:'Nos Partenaires'
        },
    }
    
    //++++++++++++++++STORE SELECTOR++++++++++++++++
    const playerName = useSelector((state) => state.Cookie.playerName); //get the current player name based on the cookie
    const playerId = useSelector((state) => state.Cookie.playerId); //get the current player name based on the cookie
    const selectedLanguage = useSelector((state) => state.Language);


    const randomWelcome = Math.floor(Math.random() * componentContent[selectedLanguage].welcomeMessage.length); //Random number to generate Welcome Message
    const randomName = Math.floor(Math.random() * componentContent[selectedLanguage].welcomeName.length); //Random number to generate Placerholder Name

    //++++++++++++++++STATE SETTINGS++++++++++++++++++

    //Show and hide the edit objects
    const [editNameMode, setEditNameVisibility] = useState(
        {
            editObjects:'none',
            defaultObjects: 'inline-flex'
        }
    );
    
    //store the player data (new Name)
    const [playerData, setPlayerData] = useState(
        {
            playerId: playerId,
            playerName:playerName,
        }
    );

    //function to set the play quiz data
    const [playQuizData, setPlayQuizData] = useState(//playQuizData which is send to db to create a new playQuiz data object. Content come from the selected quiz
        { 
            hostId: playerId,
            playerId: playerId,
            playerName:playerName
        }
    );

    //+++++++++++++++++FUCNTIONS++++++++++++++++++++ 
    //show the edit objects to edit the name
    const handleEditName = () => {
        setEditNameVisibility(
            {
                editObjects:'inline-flex',
                defaultObjects: 'none'
            }
        )
    }

    //hide the edit objects to edit the name
    const handleSaveNewName = (e) => {
        e.preventDefault();

        if(playerData.playerName !== ''){
            dispatch(changePlayerName(playerData));

            setEditNameVisibility(
                {
                    editObjects:'none',
                    defaultObjects: 'inline-flex'
                }
            )
        }
    }

    //changing the name input
    const handleChangeNameInput = (newName) => {
        setPlayerData(
            {
                playerId: playerId,
                playerName: newName,
            }        
        )

        setPlayQuizData(
            { 
                hostId: playerId,
                playerId: playerId,
                playerName:newName
            }
        )
    }

    //create a quiz
    const handleCreateQuiz = () => {
        dispatch(createPlayQuiz(playQuizData));
    }
    return(
        <div>
        <Grid container direction="column"  justifyContent="center" alignItems="center" textAlign="center" sx={{height: '100vH'}}>
            <Language />
            <div>
                <Typography variant="body1">{componentContent[selectedLanguage].welcomeMessage[randomWelcome]}</Typography>
                <Typography variant="h1" sx={{display:editNameMode.defaultObjects}}>{playerName ? playerName : componentContent[selectedLanguage].welcomeName[randomName]}</Typography>
                <form autoComplete='off' noValidate onSubmit={handleSaveNewName}>
                    <TextField 
                        defaultValue={playerName} 
                        variant="outlined" 
                        focused 
                        sx={{display:editNameMode.editObjects, backgroundColor:"lightgrey"}}
                        onChange={(e) => handleChangeNameInput(e.target.value)} 
                    />
                </form>
            </div>
            <Stack mt={2} spacing={1}>
                <Button variant="text" size="small" sx={{display:playerName ? editNameMode.defaultObjects :"none"}} onClick={handleEditName}>{componentContent[selectedLanguage].changeName}</Button>
                <Button variant="text" size="small" type="submit" sx={{display:editNameMode.editObjects}} onClick={handleSaveNewName}>{componentContent[selectedLanguage].saveName}</Button>
                <Button variant="contained" size="large" onClick={handleCreateQuiz}>{componentContent[selectedLanguage].createQuiz}</Button>
                <Button variant="contained" size="large" href='/play'>{componentContent[selectedLanguage].joinQuiz}</Button>
                
            </Stack>
        </Grid>
        <Grid container justifyContent="center" alignItems="center" textAlign="center" mb={5}>
            <Grid container maxWidth="lg" justifyContent="center" textAlign="center" spacing={1}>
                <Grid item xs={12} mb={3}>
                    <Typography variant='h2'>{componentContent[selectedLanguage].partner}</Typography>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                    <Button href="https://advisory42.com/"  target="_blank">
                        <div>
                            <div  style={{ height: '40px'}}>
                                <img src="images/sponsor_advisory42.jpg "alt="sponsor advisory42" style={{ maxHeight: '40px', maxWidth:'140px'}}/>
                            </div>
                            <div  style={{ height: '20px'}}>
                                <p style={{margin:0, color:"#F0CAA3", textTransform:"none"}}>advisory42</p>
                            </div>
                        </div>
                    </Button>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                    <Button href="https://amodoro.de/"  target="_blank">
                        <div>
                            <div  style={{ height: '40px'}}>
                                <img src="images/sponsor_amodoro.png "alt="sponsor amodoro" style={{ maxHeight: '40px', maxWidth:'140px'}}/>
                            </div>
                            <div  style={{ height: '20px'}}>
                                <p style={{margin:0, color:"#F0CAA3", textTransform:"none"}}>amodoro</p>
                            </div>
                        </div>
                    </Button>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                    <Button href="https://baumfink.de/"  target="_blank">
                        <div>
                            <div  style={{ height: '40px'}}>
                                <img src="images/sponsor_baumfink.png"alt="sponsor Baumfink" style={{ maxHeight: '40px', maxWidth:'140px'}}/>
                            </div>
                            <div  style={{ height: '20px'}}>
                                <p style={{margin:0, color:"#F0CAA3", textTransform:"none"}}>Baumfink</p>
                            </div>
                        </div>
                    </Button>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                    <Button href="https://www.landhafen.com/"  target="_blank">
                        <div>
                            <div  style={{ height: '40px'}}>
                                <img src="images/sponsor_landhafen.jpg"alt="sponsor Landhafen" style={{ maxHeight: '40px', maxWidth:'140px'}}/>
                            </div>
                            <div  style={{ height: '20px'}}>
                                <p style={{margin:0, color:"#F0CAA3", textTransform:"none"}}>Hotel Landhafen</p>
                            </div>
                        </div>
                    </Button>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                    <Button href="https://microharvest.com/"  target="_blank">
                        <div>
                            <div  style={{ height: '40px'}}>
                                <img src="images/sponsor_microharvest.png "alt="sponsor microharvest" style={{ maxHeight: '40px', maxWidth:'140px'}}/>
                            </div>
                            <div  style={{ height: '20px'}}>
                                <p style={{margin:0, color:"#F0CAA3", textTransform:"none"}}>MicroHarvest</p>
                            </div>
                        </div>
                    </Button>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                    <Button href="https://sc-victoria.de/"  target="_blank">
                        <div>
                            <div  style={{ height: '40px'}}>
                                <img src="images/sponsor_vicky.png "alt="sponsor vicky" style={{ maxHeight: '40px', maxWidth:'140px'}}/>
                            </div>
                            <div  style={{ height: '20px'}}>
                                <p style={{margin:0, color:"#F0CAA3", textTransform:"none"}}>SC Victoria Hamburg</p>
                            </div>
                        </div>
                    </Button>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                    <Button href="https://cucinaria-kochschule.de/"  target="_blank">
                        <div>
                            <div  style={{ height: '40px'}}>
                                <img src="images/sponsor_treatit.png "alt="sponsor treatit" style={{ maxHeight: '40px', maxWidth:'140px'}}/>
                            </div>
                            <div  style={{ height: '20px'}}>
                                <p style={{margin:0, color:"#F0CAA3", textTransform:"none"}}>treatit</p>
                            </div>
                        </div>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
        <Grid container justifyContent="center" alignItems="center" textAlign="center">
            <Button variant='link' href="/impressum">Impressum & {componentContent[selectedLanguage].privacy}</Button>
        </Grid>
        </div>
    )

}

export default ChooseMode