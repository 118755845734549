import {  SET_CURRENT_QUESTION, CLEAR_QUESTION, GET_ONE_QUESTION } from '../constants/actionTypes.js';
import * as api from '../api';


//++++++++++++++++++SET CURRENT QUESTION TO STORE+++++++++++++++++++++++
export const setCurrentQuestion = (question) => async (dispatch) => {
  //select a question and set it to the store
  //used e.g. to select and modify a question
  if(question){
      dispatch({ type: SET_CURRENT_QUESTION, payload: question });
    }
}

//++++++++++++++++++SET CURRENT QUESTION TO STORE+++++++++++++++++++++++
export const clearQuestion = () => async (dispatch) => {
  //clear the question store
  dispatch({ type: CLEAR_QUESTION, payload: '' });

}

//++++++++++++++++++GET ONE QUESTION+++++++++++++++++++++++
export const getOneQuestion = (questionId) => async (dispatch) => {
  try {
    //get only one question from the database
    //this is used within a quiz to provide selected information (the answers are not displayed within this api-call)
    const { data } = await api.getOneQuestion(questionId); //one question with restricted fields only used to show the question is received

    //push the question to the store. This question is shown in the quiz.
    if(data){
      dispatch({ type: GET_ONE_QUESTION, payload: data });
    } 
  //catch error block
  } catch (error) {
    console.log(error);
  }

}