import React, {useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import md5 from 'md5';

import Join from './Join/Join.js';
import EnterName from './EnterName/EnterName.js';
import Lobby from './Lobby/Lobby.js';
import Quiz from './Quiz/Quiz.js';
import Result from './Result/Result.js';
import { getPlayQuiz, reJoinPlayQuiz, playerLeave, addPlayerToQuiz } from '../../actions/playQuiz.js';
import { SocketConnection as socket } from '../../api/socketConnection.js';
import QuestionCountdownContainer from './QuestionCountdown/QuestionCountdownContainer.js';


const Play = () => {
    
    //Variables
    const dispatch = useDispatch();

    //++++++++++++++++STORE SELECTOR++++++++++++++++
    const playerData = useSelector((state) => state.Cookie); //get the current player name based on the cookie
    const playQuiz = useSelector((state) => state.PlayQuiz); //get the current player name based on the cookie

    //++++++++++++++++USE EFFECT EVENTS++++++++++++++++
    useEffect(() => {
        return () => { //clear function is executed after unmounting. If Statement is used to prevent execution on first change of playQuizQuizId
            dispatch(playerLeave());
        }

    }, [dispatch])

    //rejoin the game when socket connection gets lost
    useEffect(() => {
        socket.on('connect', () => {
            if(playQuiz.quizId && playQuiz.quizId !== 0){
                dispatch(reJoinPlayQuiz(playQuiz.quizId, {playerId: playerData.playerId})); //socket.io reconnects automatically, but doesn't join the room again. This function is executed, when the player has been in a game and the socket connection event is fired.
            }
        });
    })

    //quiz ID from URL
    const [searchParam] = useSearchParams();
    const quizIdParam = searchParam.get("quizId");
    
    //get playQuiz from DB
    if(quizIdParam && !playQuiz){
        dispatch(getPlayQuiz(quizIdParam));
    }

    //rejoin the game automatically when URL is reloaded or add a player when cookie is already set
    if(playQuiz && playerData.playerId){
        if(playQuiz.players){ //cookie is set, but player is not found in the game => add player
            if(!playQuiz.players.filter(player => player.playerId === md5(playerData.playerId))[0]){
                dispatch(addPlayerToQuiz(playQuiz._id,playerData));
            }else if(playQuiz.players.filter(player => player.playerId === md5(playerData.playerId))[0].playerSessionIsActive === false){
                dispatch(reJoinPlayQuiz(playQuiz.quizId, {playerId: playerData.playerId}));
            }
        }
    }

    if(!playQuiz){ //return JOIN Container when no quizId is mentioned in URL
        return(
            <Join />            
        )
    }else if(!playerData.playerId){ //return EnterName when no player information is stored in the cookies
        return(
            <EnterName />
        )        
    }else if(playQuiz.navigation.currentType === "start"){
        return(
            <Lobby />
        )        
    }else if(playQuiz.navigation.currentType === "countdown"){
        return(
            <QuestionCountdownContainer />
        )       
    }else if(playQuiz.navigation.currentType === "question"){
        return(
            <Quiz />
        )      
    }else if(playQuiz.navigation.currentType === "results"){
        return(
            <Result />
        )      
    }else if(playQuiz.navigation.currentType === "end"){
        return(
            <Result />
        )        
    }


}

export default Play